import React, { Component } from "react";
import { Modal, Skeleton, Row, Col, Alert } from "antd";
import "./index.scss";
import axios from "axios";
import moment from "moment-timezone";
import { formatNumber } from "../../../../helpers/numbers";
import { connect } from "react-redux";

class CustomerDetailsModal extends Component {
  state = {
    loading: false,
    error: false,
    errorMsg: "",
    customer: null,
    orders: [],
    tz: "UTC",
  };

  componentDidUpdate(pp) {
    if (pp.visible !== this.props.visible) {
      this.setState({
        loading: false,
        error: false,
        errorMsg: "",
        customer: null,
        orders: [],
      });
    }

    if (!pp.visible && this.props.visible) {
      this.fetchCustomerDetails();
    }
  }

  fetchCustomerDetails() {
    if (this.props.auth.plan.plan_features.ADVANCED_CRM !== 1) {
      return;
    }
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url: "/zerotouch/get_customerdetails",
          method: "post",
          data: {
            customerid: this.props.id,
            domid: this.props.match.params.domid,
          },
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                loading: false,
                customer: res.data.customer,
                tz: res.data.tz,
                orders: res.data.orders,
              });
            } else {
              this.setState({
                loading: false,
                error: true,
                errorMsg: res.data.msg,
              });
            }
          })
          .catch((err) => {
            this.setState({
              loading: false,
              error: true,
              errorMsg: err.message,
            });
          });
      }
    );
  }

  render() {
    return (
      <Modal
        title={
          <>
            <h1>Customer Details</h1>
            <p>Following are personal and order details of this customer:</p>
          </>
        }
        visible={this.props.visible}
        className="customer-details-modal"
        onCancel={this.props.toggleCustomerDetailsModal}
        maskClosable={false}
        footer={null}
      >
        {this.props.auth.plan.plan_features.ADVANCED_CRM === 1 ? (
          <>
            {this.state.loading && <Skeleton active />}
            {!this.state.loading && this.state.error && (
              <Alert
                message="Error"
                description={
                  this.state.errorMsg
                    ? this.state.errorMsg
                    : "There was an error!"
                }
                type="error"
                showIcon
              />
            )}
            {!this.state.loading && !this.state.error && this.state.customer && (
              <div>
                <Row gutter={12}>
                  <Col xs={12}>
                    <div className="field">
                      <label>Customer Name:</label>
                      <p>{this.state.customer.customer_name}</p>
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className="field">
                      <label>Customer Phone Number:</label>
                      <p>{this.state.customer.customer_phone}</p>
                    </div>
                  </Col>
                </Row>
                <Row gutter={12}>
                  <div className="field">
                    <label>Customer Email Address:</label>
                    <p>{this.state.customer.customer_email}</p>
                  </div>
                </Row>
                <Row gutter={12}>
                  <Col xs={12}>
                    <div className="field">
                      <label>Total Orders Placed:</label>
                      <p>{formatNumber(this.state.orders.length, 0)}</p>
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className="field">
                      <label>Total Order Amount:</label>
                      <p>
                        {this.state.orders.length
                          ? this.state.orders[0].order_currency
                          : ""}
                        {formatNumber(
                          this.state.orders.reduce(
                            (acc, o) => acc + o.order_amount,
                            0
                          )
                        )}
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row gutter={12}>
                  <div className="field">
                    <label>Last Order Placed:</label>
                    <p>
                      {this.state.orders.length
                        ? `${moment
                            .utc(this.state.orders[0].date_created)
                            .tz(this.state.tz)
                            .format("DD MMM, YYYY")} | ${moment
                            .utc()
                            .diff(
                              moment(this.state.orders[0].date_created),
                              "days"
                            )} days`
                        : ""}
                    </p>
                  </div>
                </Row>

                <Row gutter={12}>
                  <div className="field">
                    <label>Past Order List:</label>
                  </div>
                  <div className="past-orders">
                    {this.state.orders.map((order) => {
                      return (
                        <div className="order" key={order._id["$id"]}>
                          <div className="order-date">
                            {moment
                              .utc(order.date_created)
                              .tz(this.state.tz)
                              .format("DD MMM, YYYY")}{" "}
                            |{" "}
                            {moment
                              .utc()
                              .diff(moment(order.date_created), "days")}{" "}
                            days
                          </div>
                          <div className="order-items">
                            {order.items.map((item) => (
                              <>
                                <Row className="order-item">
                                  <Col xs={12}>- {item.name}</Col>
                                  <Col xs={6}>{item.quantity}</Col>
                                  <Col xs={6}>
                                    {order.order_currency}{" "}
                                    {formatNumber(item.price)}
                                  </Col>
                                </Row>
                                {item.addons &&
                                Object.values(item.addons).length > 0
                                  ? Object.values(item.addons).map((addon) => (
                                      <Row
                                        className="order-item"
                                        key={addon.id}
                                      >
                                        <Col
                                          xs={18}
                                          style={{ paddingLeft: 20 }}
                                        >
                                          - {addon.name}
                                        </Col>
                                        <Col xs={6}>
                                          {order.order_currency}{" "}
                                          {formatNumber(addon.price)}
                                        </Col>
                                      </Row>
                                    ))
                                  : null}
                              </>
                            ))}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Row>
              </div>
            )}
          </>
        ) : (
          <Alert
            message="Error"
            description="Your plan does not allow you to access Advanced CRM!"
            type="error"
            showIcon
          />
        )}
      </Modal>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth.auth,
});
export default connect(mapStateToProps)(CustomerDetailsModal);
