import React, { Component } from "react";
import { Modal, Icon, Button } from "antd";
import "./index.scss";
import { ReactComponent as wavingHand } from "../../../../assets/images/geminii/autoresponder/Waving_Hand_Sign.svg";
import { ReactComponent as GettingStartedSVG } from "../../../../assets/images/getting_started.svg";
import { connect } from "react-redux";

class CampaignStepsModal extends Component {
  state = {
    loading: false,
    name: "",
    remarks: "",
    data: [],
  };

  onSelect = (url) => {
    this.props.toggleCampaignStepsModal();
    this.props.history.push(url);
  };

  render() {
    return (
      <Modal
        title={null}
        width={666}
        visible={this.props.visible}
        onCancel={this.props.toggleCampaignStepsModal}
        className="campaign-steps"
        footer={null}
        destroyOnClose={true}
        maskClosable={false}
      >
        <div className="main-steps-container">
          <div className="steps-header">
            <div className="step-info">
              <h1>
                {" "}
                <Icon component={wavingHand} />
                Hey {this.props.auth.user.user_fname}
              </h1>
              <p>
                You only need 5 simple steps to get rolling with ZeroTouch App:
              </p>
              <div className="step-name">
                <Button
                  type="link"
                  onClick={() =>
                    this.onSelect(`/${this.props.match.params.domid}/settings`)
                  }
                >
                  1. Setup Brand
                </Button>
                <p>Add your brand name & logo to set up brand</p>
              </div>
              <div className="step-name">
                <Button
                  type="link"
                  onClick={() =>
                    this.onSelect(
                      `/${this.props.match.params.domid}/categories`
                    )
                  }
                >
                  2. Add Category
                </Button>
                <p>Create a list of categories of your services</p>
              </div>
            </div>
            <GettingStartedSVG />
          </div>
          <div className="step-name">
            <Button
              type="link"
              onClick={() =>
                this.onSelect(`/${this.props.match.params.domid}/products`)
              }
            >
              3. Add Product
            </Button>
            <p>Add products to each category</p>
          </div>
          <div className="step-name">
            <Button
              type="link"
              onClick={() =>
                this.onSelect(`/${this.props.match.params.domid}/catalog`)
              }
            >
              4. Create Catalog
            </Button>
            <p>
              Create catalog with the Products & Categories that you’ve created
              for your business
            </p>
          </div>
          <div className="step-name">
            <Button
              type="link"
              onClick={() =>
                this.onSelect(`/${this.props.match.params.domid}/stations`)
              }
            >
              5. Add Stations
            </Button>
            <p>
              Create station ids for the tables to allow for identification of
              origin of contactless orders that customers are placing in your
              physical business space
            </p>
          </div>
        </div>
      </Modal>
    );
  }
}

// export default CampaignStepsModal;
const mapStateToProps = (state) => ({
  auth: state.auth.auth,
});
export default connect(mapStateToProps)(CampaignStepsModal);
