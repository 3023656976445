import React, { Component } from "react";
import "./index.scss";
import { Form, Icon, Input, Button, Tooltip, Card } from "antd";
import { ReactComponent as ArrowRight } from "../../../assets/images/icons/arrowright.svg";
import { connect } from "react-redux";

class OnboardingForm extends Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { dom_name, dom_domain } = values;
        this.props.add_domain(dom_name, dom_domain);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Card style={{ width: "100%", maxWidth: 525, marginTop: 60 }}>
        <Form onSubmit={this.handleSubmit} className="onboarding-form">
          <div style={{ textAlign: "center" }}>
            <h1>Let’s set up your account</h1>
            <p>Enter your details below to get started with Zerotouch</p>
          </div>
          <Form.Item>
            <p className="input-label">Store/Brand Name</p>

            <Tooltip
              overlayClassName="onboarding-tooltip"
              placement="right"
              title="This is your store name."
            >
              {getFieldDecorator("dom_name", {
                rules: [
                  {
                    required: true,
                    message: "Please input your store name!",
                  },
                ],
              })(<Input placeholder="Your Store Name" />)}
            </Tooltip>
          </Form.Item>
          <Form.Item>
            <p className="input-label label-2">Store Subdomain</p>
            <Tooltip
              overlayClassName="onboarding-tooltip"
              placement="right"
              title={`This is a subdomain on zerotouch.agency on which your store will be accessible.`}
            >
              {getFieldDecorator("dom_domain", {
                rules: [
                  { required: true, message: "Please enter a subdomain!" },
                  {
                    pattern: /^[a-z0-9-]+$/,
                    message: "Subdomain can only contain a-z, 0-9, -",
                  },
                ],
              })(
                <Input
                  placeholder="mystore"
                  addonBefore="https://"
                  addonAfter=".zerotouch.agency"
                />
              )}
            </Tooltip>
          </Form.Item>
          <Form.Item>
            <Button type="default" htmlType="submit">
              Let's Get Started <Icon component={ArrowRight} />
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth.auth,
});

export default Form.create({ name: "onboarding_form" })(
  connect(mapStateToProps)(OnboardingForm)
);
