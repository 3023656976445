import React, { Component } from "react";
import MainHeadingBar from "../Partials/MainHeadingBar";
import { connect } from "react-redux";
import "./index.scss";
import { Row, Card, Col } from "antd";
import Meta from "antd/lib/card/Meta";
import link1Image from "../../../assets/images/cue_cards/1.JPG";
import link2Image from "../../../assets/images/cue_cards/2.JPG";
import link3Image from "../../../assets/images/cue_cards/3.JPG";
import link4Image from "../../../assets/images/cue_cards/4.JPG";
import link5Image from "../../../assets/images/cue_cards/5.JPG";

class DFYCueCards extends Component {
  state = {
    cards: [
      {
        image: link1Image,
        url:
          "https://www.canva.com/design/DAEF5RV4pD4/kEzlRrFGxiAS31v4I1Bm_A/view?utm_content=DAEF5RV4pD4&utm_campaign=designshare&utm_medium=link&utm_source=sharebutton&mode=preview",
      },
      {
        image: link2Image,
        url:
          "https://www.canva.com/design/DAEF-Xkx-Wc/ZuP8p1pJer_smoCXrXrXxw/view?utm_content=DAEF-Xkx-Wc&utm_campaign=designshare&utm_medium=link&utm_source=sharebutton&mode=preview",
      },
      {
        image: link3Image,
        url:
          "https://www.canva.com/design/DAEF-T5PU4A/2qkVlrfuULmxVmhtM_RCbg/view?utm_content=DAEF-T5PU4A&utm_campaign=designshare&utm_medium=link&utm_source=sharebutton&mode=preview",
      },
      {
        image: link4Image,
        url:
          "https://www.canva.com/design/DAEF-XQIQ-0/Yatc3AdUk85iIsWfZvrzJA/view?utm_content=DAEF-XQIQ-0&utm_campaign=designshare&utm_medium=link&utm_source=sharebutton&mode=preview",
      },
      {
        image: link5Image,
        url:
          "https://www.canva.com/design/DAEF-Rqrv-k/v3mkUPcylSZ56QoaKI2W0g/view?utm_content=DAEF-Rqrv-k&utm_campaign=designshare&utm_medium=link&utm_source=sharebutton&mode=preview",
      },
    ],
  };
  render() {
    return (
      <div className="bonus-page">
        <MainHeadingBar title="DFY Cue Cards" {...this.props} hideCTA />
        <Row gutter={20}>
          {this.state.cards.map((link, index) => {
            return (
              <Col xs={6} key={index}>
                <Card
                  style={{
                    marginBottom: 30,
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                    overflow: "hidden",
                  }}
                  cover={
                    <div
                      style={{
                        height: 500,
                        background: `url(${link.image})`,
                      }}
                    >
                      <img
                        alt={`Cue Card ${index + 1}`}
                        src={link.image}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                          backdropFilter: `blur(10px)`,
                        }}
                      />
                    </div>
                  }
                >
                  <Meta
                    style={{ textAlign: "center" }}
                    title={
                      <a
                        href={link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Add to My Canva.com Account
                      </a>
                    }
                  />
                </Card>
              </Col>
            );
          })}
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth.auth,
});

export default connect(mapStateToProps)(DFYCueCards);
