import React, { Component } from "react";
import "./index.scss";
import {
  Button,
  Divider,
  Row,
  Col,
  Input,
  Icon,
  Skeleton,
  Alert,
  message,
  Tooltip,
  Select,
  Modal,
  Result,
} from "antd";
import { CDN_URL } from "../../../../config";
import LibraryModal from "../../Library/LibraryModal";
import { connect } from "react-redux";
import axios from "axios";
import TextArea from "antd/lib/input/TextArea";
import ColorPicker from "../../ColorPicker";

class AgencySiteBuilder extends Component {
  state = {
    error: false,
    errorMsg: "",
    loading: false,
    saving: false,
    agency: {
      agency_name: "",
      agency_logo: "",
      agency_favicon: "",
      agency_wlbldom: "",
      agency_slug: "",
      agency_basecolor: "#ff0000",
      agency_video: "",
      agency_tnc: "",
      agency_privacy_policy: "",
      agency_sales_email: "",
      agency_office_add: "",
      agency_qrcodeurl: "",
      agency_cta: {
        type: "redirect",
        url: "",
        code: "",
      },
    },
    errors: {},
    libraryModal: {
      visible: false,
      tab: "upload",
    },
  };

  toggleLibraryModal(tab = "upload", field = "logo") {
    this.setState((state) => ({
      libraryModal: {
        ...state.libraryModal,
        visible: !state.libraryModal.visible,
        tab,
        field,
      },
    }));
  }

  onChange = (k, v) => {
    this.setState((state) => ({
      agency: {
        ...state.agency,
        [k]: v,
      },
    }));
  };

  onChoose(data) {
    this.onChange(this.state.libraryModal.field, data.media_filename);
    this.toggleLibraryModal();
  }

  fetchSettings() {
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url: "/agency/get_agency",
          method: "post",
        })
          .then((res) => {
            this.setState({ loading: false });
            return res;
          })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                agency: {
                  ...this.state.agency,
                  ...res.data.data,
                },
              });
            } else {
              this.setState({
                error: true,
                errorMsg: res.data.msg,
              });
            }
          })
          .catch((err) => {
            this.setState({
              error: true,
              errorMsg: "There was an error!",
            });
            console.log(err);
          });
      }
    );
  }

  validate() {
    const agency = this.state.agency;
    const errors = {};
    if (!agency.agency_name) {
      errors.agency_name = "This is a required field!";
    }
    if (!agency.agency_logo) {
      errors.agency_logo = "This is a required field!";
    }
    if (!agency.agency_favicon) {
      errors.agency_favicon = "This is a required field!";
    }
    if (!agency.agency_slug) {
      errors.agency_slug = "This is a required field!";
    }
    if (!/^[a-z0-9-]+$/.test(agency.agency_slug)) {
      errors.agency_slug = "Slug can only contain a-z, 0-9 & -";
    }
    if (!agency.agency_basecolor) {
      errors.agency_basecolor = "This is a required field!";
    }
    if (!agency.agency_tnc) {
      errors.agency_tnc = "This is a required field!";
    }
    if (!agency.agency_qrcodeurl) {
      errors.agency_qrcodeurl = "This is a required field!";
    }
    if (!agency.agency_privacy_policy) {
      errors.agency_privacy_policy = "This is a required field!";
    }
    if (!agency.agency_cta) {
      errors.agency_ctadata = "This is a required field!";
    }
    if (agency.agency_cta.type.includes("redirect") && !agency.agency_cta.url) {
      errors.agency_ctadata = "This is a required field!";
    }
    if (agency.agency_cta.type === "jscode" && !agency.agency_cta.code) {
      errors.agency_ctadata = "This is a required field!";
    }

    this.setState({
      errors,
    });

    if (Object.keys(errors).length) {
      return false;
    }
    return true;
  }

  saveSettings() {
    if (this.validate()) {
      this.setState(
        {
          saving: true,
        },
        () => {
          axios({
            url: "/agency/add_agency",
            method: "post",
            data: {
              ...this.state.agency,
            },
          })
            .then((res) => {
              this.setState({ saving: false });
              return res;
            })
            .then((res) => {
              if (res.data.success === 1) {
                Modal.success({
                  icon: null,
                  className: "agencySiteModal",
                  okButtonProps: { block: true },
                  content: (
                    <Result
                      status="success"
                      title="Website created successfully!"
                      style={{ padding: 10 }}
                      subTitle={
                        <p>
                          You can access your website at{" "}
                          <a
                            href={`https://${
                              this.state.agency.agency_slug
                            }.ztsite.club`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            https://{this.state.agency.agency_slug}.ztsite.club
                          </a>
                        </p>
                      }
                      extra={null}
                    />
                  ),
                });
              } else {
                message.error(res.data.msg);
              }
            })
            .catch((err) => {
              message.error("There was an error!");
              console.log(err);
            });
        }
      );
    }
  }

  componentDidMount = () => {
    this.fetchSettings();
  };

  onChangeCTA = (k, v) => {
    this.setState((state) => ({
      agency: {
        ...state.agency,
        agency_cta: {
          ...state.agency.agency_cta,
          [k]: v,
        },
      },
    }));
  };

  render() {
    const { error, errorMsg, loading } = this.state;
    if (loading) {
      return (
        <div style={{ marginBottom: "30px" }}>
          <Skeleton active />
        </div>
      );
    }

    if (!loading && error) {
      return (
        <div>
          <Row>
            <Alert
              message="There was an error"
              description={errorMsg}
              type="error"
            />
          </Row>
        </div>
      );
    }
    const agency = this.state.agency;
    return (
      <div className="account-settings">
        <h3 className="heading">
          <span className="text">
            Agency Website{" "}
            <Tooltip title="Here you can manage the settings for your Agency Website.">
              <Icon type="info-circle" style={{ fontSize: "1.5rem" }} />
            </Tooltip>
          </span>
          <Button
            type="default"
            onClick={() => this.saveSettings()}
            loading={this.state.saving}
          >
            Save Settings
          </Button>
        </h3>

        <Divider />

        <Row gutter={24}>
          <Col xs={24}>
            <div className="input-container">
              <p className="label">Website Name:</p>
              <Input
                value={agency.agency_name}
                onChange={(e) => this.onChange("agency_name", e.target.value)}
              />
              {this.state.errors.agency_name && (
                <p className="error-label">{this.state.errors.agency_name}</p>
              )}
            </div>

            <div className="input-container">
              <p className="label">Select Logo:</p>
              <div className="upload-actions">
                {agency.agency_logo && (
                  <img
                    alt="store logo"
                    src={`${CDN_URL}images/${agency.agency_logo}`}
                    style={{
                      maxWidth: "7.14rem",
                      marginRight: 10,
                      border: "1px solid #ccc",
                    }}
                  />
                )}
                <div
                  className="upload-action"
                  onClick={() =>
                    this.toggleLibraryModal("upload", "agency_logo")
                  }
                >
                  <p>
                    <Icon type="upload" style={{ color: "#D9D9D9" }} /> <br />
                    Upload
                  </p>
                </div>
                <div
                  className="upload-action"
                  onClick={() =>
                    this.toggleLibraryModal("library", "agency_logo")
                  }
                >
                  <p>
                    <Icon type="folder-open" style={{ color: "#D9D9D9" }} />{" "}
                    <br />
                    Library
                  </p>
                </div>
              </div>
              {this.state.errors.agency_logo && (
                <p className="error-label">{this.state.errors.agency_logo}</p>
              )}
            </div>

            <div className="input-container">
              <p className="label">Select Favicon:</p>
              <div className="upload-actions">
                {agency.agency_favicon && (
                  <img
                    alt="store favicon"
                    src={`${CDN_URL}images/${agency.agency_favicon}`}
                    style={{
                      maxWidth: "7.14rem",
                      marginRight: 10,
                      border: "1px solid #ccc",
                    }}
                  />
                )}
                <div
                  className="upload-action"
                  onClick={() =>
                    this.toggleLibraryModal("upload", "agency_favicon")
                  }
                >
                  <p>
                    <Icon type="upload" style={{ color: "#D9D9D9" }} /> <br />
                    Upload
                  </p>
                </div>
                <div
                  className="upload-action"
                  onClick={() =>
                    this.toggleLibraryModal("library", "agency_favicon")
                  }
                >
                  <p>
                    <Icon type="folder-open" style={{ color: "#D9D9D9" }} />{" "}
                    <br />
                    Library
                  </p>
                </div>
              </div>
              {this.state.errors.agency_favicon && (
                <p className="error-label">
                  {this.state.errors.agency_favicon}
                </p>
              )}
            </div>

            <div className="input-container">
              <p className="label">Website Slug:</p>
              <Input
                value={agency.agency_slug}
                onChange={(e) => this.onChange("agency_slug", e.target.value)}
                addonBefore="https://"
                addonAfter=".ztsite.club"
              />
              {this.state.errors.agency_slug && (
                <p className="error-label">{this.state.errors.agency_slug}</p>
              )}
            </div>
            <div className="input-container">
              <p className="label">Whitelabel Domain:</p>
              <Input
                value={agency.agency_wlbldom}
                onChange={(e) =>
                  this.onChange("agency_wlbldom", e.target.value)
                }
              />
              {this.state.errors.agency_wlbldom && (
                <p className="error-label">
                  {this.state.errors.agency_wlbldom}
                </p>
              )}
            </div>

            <div
              style={{
                display: "flex",
                flex: 1,
                alignItems: "flex-start",
                paddingLeft: 10,
                flexDirection: "column",
              }}
              className="input-container"
            >
              <p
                className="label"
                style={{ display: "inline-block", margin: 0 }}
              >
                Base Color Theme
              </p>
              <ColorPicker
                label=""
                defaultValue={agency.agency_basecolor}
                onChange={(color) => this.onChange("agency_basecolor", color)}
                style={{ display: "inline-block", margin: "10px 0" }}
              />
            </div>

            <div className="input-container">
              <p className="label">CTA Action:</p>
              <Select
                style={{ width: "100%" }}
                value={agency.agency_cta.type}
                onChange={(val) => this.onChangeCTA("type", val)}
              >
                <Select.Option value="redirect">
                  Redirect in same tab
                </Select.Option>
                <Select.Option value="redirect_newtab">
                  Redirect in new tab
                </Select.Option>
                <Select.Option value="jscode" data-label="JS Code">
                  Trigger JS Code
                </Select.Option>
              </Select>
            </div>

            <div className="input-container">
              <p className="label">
                {agency.agency_cta.type.includes("redirect")
                  ? "CTA Redirect URL"
                  : "JS Code"}
                :
              </p>
              {agency.agency_cta.type.includes("redirect") ? (
                <Input
                  value={agency.agency_cta.url}
                  onChange={(e) => this.onChangeCTA("url", e.target.value)}
                  onBlur={(e) =>
                    /^https?:\/\//.test(e.target.value)
                      ? null
                      : this.onChangeCTA("url", `http://${e.target.value}`)
                  }
                />
              ) : (
                <TextArea
                  value={agency.agency_cta.code}
                  onChange={(e) => this.onChangeCTA("code", e.target.value)}
                />
              )}

              {this.state.errors.agency_ctadata && (
                <p className="error-label">
                  {this.state.errors.agency_ctadata}
                </p>
              )}
            </div>

            <div className="input-container">
              <p className="label">Youtube/Vimeo Video Embed Code:</p>
              <Input
                value={agency.agency_video}
                onChange={(e) => this.onChange("agency_video", e.target.value)}
              />
              {this.state.errors.agency_video && (
                <p className="error-label">{this.state.errors.agency_video}</p>
              )}
            </div>

            <div className="input-container">
              <p className="label">Terms & Conditions Page Link:</p>
              <Input
                value={agency.agency_tnc}
                onChange={(e) => this.onChange("agency_tnc", e.target.value)}
              />
              {this.state.errors.agency_tnc && (
                <p className="error-label">{this.state.errors.agency_tnc}</p>
              )}
            </div>

            <div className="input-container">
              <p className="label">Privacy Policy Page Link:</p>
              <Input
                value={agency.agency_privacy_policy}
                onChange={(e) =>
                  this.onChange("agency_privacy_policy", e.target.value)
                }
              />
              {this.state.errors.agency_privacy_policy && (
                <p className="error-label">
                  {this.state.errors.agency_privacy_policy}
                </p>
              )}
            </div>

            <div className="input-container">
              <p className="label">Sales Email:</p>
              <Input
                value={agency.agency_sales_email}
                onChange={(e) =>
                  this.onChange("agency_sales_email", e.target.value)
                }
              />
              {this.state.errors.agency_sales_email && (
                <p className="error-label">
                  {this.state.errors.agency_sales_email}
                </p>
              )}
            </div>

            <div className="input-container">
              <p className="label">QR Code URL:</p>
              <Input
                value={agency.agency_qrcodeurl}
                onChange={(e) =>
                  this.onChange("agency_qrcodeurl", e.target.value)
                }
                onBlur={(e) => {
                  if (e.target.value && !/^https?:\/\//.test(e.target.value)) {
                    this.onChange(
                      "agency_qrcodeurl",
                      "https://" + e.target.value
                    );
                  }
                }}
              />
              {this.state.errors.agency_qrcodeurl && (
                <p className="error-label">
                  {this.state.errors.agency_qrcodeurl}
                </p>
              )}
            </div>

            <div className="input-container">
              <p className="label">Office Address:</p>
              <TextArea
                value={agency.agency_office_add}
                onChange={(e) =>
                  this.onChange("agency_office_add", e.target.value)
                }
              />
              {this.state.errors.agency_office_add && (
                <p className="error-label">
                  {this.state.errors.agency_office_add}
                </p>
              )}
            </div>

            <div className="heading">
              <Button
                type="default"
                onClick={() => this.saveSettings()}
                loading={this.state.saving}
              >
                Save Settings
              </Button>
            </div>
          </Col>
        </Row>

        <Alert
          style={{ marginTop: 30 }}
          message="How To Point your Domain"
          description={
            <div class="whitelabel-instructions">
              <p>1. Copy the following URL: wlbl.ztsite.club</p>
              <p>2. Navigate to your domain provider</p>
              <p>3. Visit your domain management page</p>
              <p>
                4. Create a new CNAME record for your domain{" "}
                <i>(sometimes referred to as an alias)</i>
              </p>
              <p>
                5. In the name field, set the CNAME to the same value as your
                subdomain{" "}
                <i>
                  (for example, if you selected 'store' as your desired
                  subdomain, your CNAME would also be set to 'store')
                </i>
              </p>
              <p>
                6. In the field labeled value, paste the URL link copied above
              </p>
              <p>
                7. Leave all other options set at their recommended default
                settings
              </p>
            </div>
          }
          type="info"
        />

        <LibraryModal
          {...this.props}
          tab={this.state.libraryModal.tab}
          onCancel={() => this.toggleLibraryModal()}
          visible={this.state.libraryModal.visible}
          onChoose={(data) => this.onChoose(data)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};

export default connect(mapStateToProps)(AgencySiteBuilder);
