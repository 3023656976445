import React, { Component } from "react";
import MainHeadingBar from "../../Partials/MainHeadingBar";
import LibraryModal from "../../Library/LibraryModal";
import ColorPicker from "../../ColorPicker";
import {
  Card,
  Input,
  Button,
  Icon,
  Select,
  message,
  Row,
  Col,
  Skeleton,
  Switch,
  TimePicker,
} from "antd";
import { CDN_URL } from "../../../../config";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./index.scss";
import axios from "axios";
import { connect } from "react-redux";
import TextArea from "antd/lib/input/TextArea";
import moment, { isMoment } from "moment";
const { Option } = Select;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  padding: 5,
  border: "1px solid #bbb",
  borderRadius: 4,
  margin: "5px 0",
  background: "white",
  userSelect: "none",

  // drag changes
  boxShadow: isDragging ? "1px 3px 3px rgba(0,0,0,0.3)" : "none",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "#a5d7ff" : "white",
  padding: 8,
  width: "100%",
});

const paymentGatewayTypes = {
  cash: "Cash",
  stripe: "Stripe",
  razorpay: "Razorpay",
  paypal: "PayPal",
};
class CreateCatalog extends Component {
  state = {
    loading: false,
    submitting: false,
    prefetching: false,
    catalog: {
      name: "",
      slug: "",
      internal_name: "",
      categories: [],
      maincolor: "#f1f1f2",
      logo: "",
      wlbldom: "",
      favicon: "",
      catalog_announcements: [],
      catalog_announcementtickers: [],
      catalog_paymentgateways: [],
      pwa_enabled: 1,
      translation_enabled: 0,
      catalog_translationid: "",
      catalog_socialsettings: {
        enabled: 0,
        description: "",
        facebook: "",
        twitter: "",
        instagram: "",
        whatsapp: "",
      },
      catalog_footerlinks: {
        enabled: 0,
        links: [{ id: `${Date.now()}`, title: "", url: "" }],
      },
      catalog_tips: {
        enabled: 0,
        required: 0,
        type: "percentage",
        tips: [
          { currency: "", amount: 0 },
          { currency: "", amount: 0 },
          { currency: "", amount: 0 },
          { currency: "", amount: 0 },
        ],
      },
      catalog_dinein: {
        enabled: 1,
        days: [],
        timings: [{ start: "", end: "" }],
      },
      catalog_takeaway: {
        enabled: 0,
        days: [],
        timings: [{ start: "", end: "" }],
      },
      catalog_delivery: {
        enabled: 0,
        amount: 0,
        days: [],
        timings: [{ start: "", end: "" }],
      },
      orderpage_pixel: "",
      meta_title: "",
      meta_desc: "",
      meta_image: "",
    },
    payment_gateways: [],
    categories: [],
    currencies: [],
    announcements: [],
    announcement_tickers: [],
    wlbldoms: [],
    translations: [],
    libraryModal: {
      visible: false,
      tab: "upload",
    },
    errors: {},
  };

  fetchCategories() {
    axios({
      url: "/zerotouch/get_categories",
      method: "post",
      data: {
        page: 1,
        pageSize: 100000,
        domid: this.props.match.params.domid,
      },
    })
      .then((res) => {
        if (res.data.success === 1) {
          this.setState({
            categories: res.data.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  fetchTranslations() {
    axios({
      url: "/zerotouch/get_translations",
      method: "post",
      data: {
        page: 1,
        pageSize: 100000,
        domid: this.props.match.params.domid,
      },
    })
      .then((res) => {
        if (res.data.success === 1) {
          this.setState({
            translations: res.data.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  fetchAnnouncements() {
    axios({
      url: "/zerotouch/get_announcements",
      method: "post",
      data: {
        page: 1,
        pageSize: 100000,
        domid: this.props.match.params.domid,
      },
    })
      .then((res) => {
        if (res.data.success === 1) {
          this.setState({
            announcements: res.data.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  fetchAnnouncementTickers() {
    axios({
      url: "/zerotouch/get_announcement_tickers",
      method: "post",
      data: {
        page: 1,
        pageSize: 100000,
        domid: this.props.match.params.domid,
      },
    })
      .then((res) => {
        if (res.data.success === 1) {
          this.setState({
            announcement_tickers: res.data.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  fetchCatalogData() {
    this.setState(
      {
        prefetching: true,
      },
      () => {
        axios({
          url: "/zerotouch/get_catalogbyid",
          method: "post",
          data: {
            id: this.props.match.params.id,
            domid: this.props.match.params.domid,
          },
        })
          .then((res) => {
            this.setState({
              prefetching: false,
              catalog: {
                ...this.state.catalog,
                ...res.data.default,
              },
            });
            return res;
          })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                catalog: {
                  ...this.state.catalog,
                  ...res.data.catalog,
                  edit: this.props.match.params.id,
                },
              });
            } else {
              if (this.props.edit) {
                this.props.history.push("/404");
              }
            }
          })
          .catch((err) => {
            this.props.history.push("/404");
          });
      }
    );
  }

  fetchWhitelabelDomains() {
    axios({
      url: "/settings/get_whitelabel_domains",
      method: "post",
      data: {
        domid: this.props.match.params.domid,
      },
    })
      .then((res) => {
        if (res.data.success === 1) {
          this.setState({
            wlbldoms: res.data.domains.filter(
              (dom) => dom.domain_status === "Pointed"
            ),
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  fetchPaymentGateways() {
    axios({
      url: "/zerotouch/get_payment_methods",
      method: "post",
      data: {
        page: 1,
        pageSize: 10000,
        domid: this.props.match.params.domid,
      },
    })
      .then((res) => {
        if (res.data.success === 1) {
          this.setState({
            payment_gateways: res.data.data.filter(
              (pg) => Number(pg.active) === 1
            ),
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentDidMount() {
    this.fetchCategories();
    this.fetchAnnouncements();
    this.fetchAnnouncementTickers();
    this.fetchWhitelabelDomains();
    this.fetchPaymentGateways();
    this.fetchTranslations();
    this.fetchCatalogData();
  }

  validate = () => {
    const catalog = this.state.catalog;
    const errors = {};
    if (!catalog.name) {
      errors.name = "This is a required field!";
    }
    if (!catalog.slug) {
      errors.slug = "This is a required field!";
    } else {
      if (!catalog.slug.match(/^[a-zA-Z0-9-]+$/)) {
        errors.slug = "Slug can only contain a-z, A-Z, 0-9, -";
      }
    }
    if (!catalog.internal_name) {
      errors.internal_name = "This is a required field!";
    }
    if (catalog.catalog_paymentgateways.length <= 0) {
      errors.catalog_paymentgateways = "This is a required field!";
    }
    if (catalog.translation_enabled === 1 && !catalog.catalog_translationid) {
      errors.catalog_translationid = "This is a required field!";
    }

    if (
      catalog.catalog_footerlinks &&
      catalog.catalog_footerlinks.enabled === 1
    ) {
      catalog.catalog_footerlinks.links.forEach((link) => {
        if (!link.title) {
          errors[`link_${link.id}_title`] = "This is a required field";
        }
        if (!link.url) {
          errors[`link_${link.id}_url`] = "This is a required field";
        }
      });
    }

    if (catalog.catalog_tips && catalog.catalog_tips.enabled === 1) {
      if (catalog.catalog_tips.type === "percentage") {
        if (!catalog.catalog_tips.tips_percentage) {
          errors.tips_percentage = "This is a required field!";
        }
      } else {
        const totalTips = catalog.catalog_tips.tips.reduce(
          (a, b) => a + b.amount,
          0
        );
        if (totalTips <= 0) {
          errors.tips_custom = "This is a required field!";
        }
      }
    }

    if (
      catalog.catalog_delivery.enabled !== 1 &&
      catalog.catalog_dinein.enabled !== 1 &&
      catalog.catalog_takeaway.enabled !== 1
    ) {
      errors.form_error =
        "Please enable atleast one: Dine-In, Takeaway or Delivery!";
    }

    if (catalog.catalog_delivery.enabled === 1) {
      if (catalog.catalog_delivery.amount < 0) {
        errors.delivery_charge = "This is a required field!";
      }
      if (!catalog.catalog_delivery.days.length) {
        errors.delivery_days = "This is a required field!";
      }
      if (catalog.catalog_delivery.timings.length === 0) {
        errors.delivery_timing = "This is a required field!";
      }
      catalog.catalog_delivery.timings.forEach((t, idx) => {
        if (!t.start || !t.end) {
          errors[`delivery_timing_${idx}`] = "This is a required field!";
        }
      });
    }
    if (catalog.catalog_dinein.enabled === 1) {
      if (!catalog.catalog_dinein.days.length) {
        errors.dinein_days = "This is a required field!";
      }
      if (catalog.catalog_dinein.timings.length === 0) {
        errors.dinein_timing = "This is a required field!";
      }
      catalog.catalog_dinein.timings.forEach((t, idx) => {
        if (!t.start || !t.end) {
          errors[`dinein_timing_${idx}`] = "This is a required field!";
        }
      });
    }
    if (catalog.catalog_takeaway.enabled === 1) {
      if (!catalog.catalog_takeaway.days.length) {
        errors.takeaway_days = "This is a required field!";
      }
      if (catalog.catalog_takeaway.timings.length === 0) {
        errors.takeaway_timing = "This is a required field!";
      }
      catalog.catalog_takeaway.timings.forEach((t, idx) => {
        if (!t.start || !t.end) {
          errors[`takeaway_timing_${idx}`] = "This is a required field!";
        }
      });
    }
    this.setState({ errors });
    if (Object.keys(errors).length > 0) {
      return false;
    }
    return true;
  };

  onSubmit = () => {
    if (this.validate()) {
      this.setState(
        {
          submitting: true,
        },
        () => {
          axios({
            url: "/zerotouch/add_catalog",
            method: "post",
            data: {
              catalog: this.state.catalog,
              domid: this.props.match.params.domid,
            },
          })
            .then((res) => {
              this.setState({
                submitting: false,
              });
              return res;
            })
            .then((res) => {
              if (res.data.success === 1) {
                this.props.history.push(
                  `/${this.props.match.params.domid}/catalog`
                );
              } else {
                message.error(res.data.msg);
              }
            })
            .catch((err) => {
              message.error("There was an error!");
              console.log(err);
            });
        }
      );
    }
  };

  onChange = (k, v) => {
    this.setState((state) => ({
      catalog: {
        ...state.catalog,
        [k]: v,
      },
    }));
  };

  onNameFieldBlur(val) {
    if (!this.state.catalog.slug) {
      this.onChange("slug", val.replace(/\s/g, "-"));
    }
  }

  onChangeSocialSettings(k, v) {
    this.setState((state) => ({
      catalog: {
        ...state.catalog,
        catalog_socialsettings: {
          ...state.catalog.catalog_socialsettings,
          [k]: v,
        },
      },
    }));
  }

  toggleFooterLinks(enabled) {
    this.setState((state) => ({
      catalog: {
        ...state.catalog,
        catalog_footerlinks: {
          ...state.catalog.catalog_footerlinks,
          enabled,
        },
      },
    }));
  }

  addFooterLink() {
    this.setState((state) => ({
      catalog: {
        ...state.catalog,
        catalog_footerlinks: {
          ...state.catalog.catalog_footerlinks,
          links: [
            ...state.catalog.catalog_footerlinks.links,
            { title: "", url: "", id: `${Date.now()}` },
          ],
        },
      },
    }));
  }

  removeFooterLink(id) {
    this.setState((state) => ({
      catalog: {
        ...state.catalog,
        catalog_footerlinks: {
          ...state.catalog.catalog_footerlinks,
          links: state.catalog.catalog_footerlinks.links.filter(
            (l) => l.id !== id
          ),
        },
      },
    }));
  }

  updateFooterLink(id, k, v) {
    this.setState((state) => ({
      catalog: {
        ...state.catalog,
        catalog_footerlinks: {
          ...state.catalog.catalog_footerlinks,
          links: state.catalog.catalog_footerlinks.links.map((l) => {
            if (l.id === id) {
              l[k] = v;
            }
            return l;
          }),
        },
      },
    }));
  }

  onChangeTips(k, v) {
    this.setState((state) => ({
      catalog: {
        ...state.catalog,
        catalog_tips: {
          ...state.catalog.catalog_tips,
          [k]: v,
        },
      },
    }));
  }

  onChangeDineIn(k, v) {
    this.setState((state) => ({
      catalog: {
        ...state.catalog,
        catalog_dinein: {
          ...state.catalog.catalog_dinein,
          [k]: v,
        },
      },
    }));
  }
  onChangeTakeaway(k, v) {
    this.setState((state) => ({
      catalog: {
        ...state.catalog,
        catalog_takeaway: {
          ...state.catalog.catalog_takeaway,
          [k]: v,
        },
      },
    }));
  }
  onChangeDelivery(k, v) {
    this.setState((state) => ({
      catalog: {
        ...state.catalog,
        catalog_delivery: {
          ...state.catalog.catalog_delivery,
          [k]: v,
        },
      },
    }));
  }

  addCatalogTiming(k) {
    this.setState((state) => ({
      catalog: {
        ...state.catalog,
        [k]: {
          ...state.catalog[k],
          timings: [...state.catalog[k].timings, { start: "", end: "" }],
        },
      },
    }));
  }
  removeCatalogTiming(k, idx) {
    const timings = this.state.catalog[k].timings.filter((_, i) => i !== idx);
    this.setState((state) => ({
      catalog: {
        ...state.catalog,
        [k]: {
          ...state.catalog[k],
          timings,
        },
      },
    }));
  }
  updateCatalogTiming(k, idx, key, v) {
    const timings = [...this.state.catalog[k].timings];
    timings[idx][key] = v;
    this.setState((state) => ({
      catalog: {
        ...state.catalog,
        [k]: {
          ...state.catalog[k],
          timings,
        },
      },
    }));
  }

  toggleLibraryModal(tab = "upload", key) {
    this.setState((state) => ({
      libraryModal: {
        ...state.libraryModal,
        visible: !state.libraryModal.visible,
        key,
        tab,
      },
    }));
  }

  onChoose(data) {
    this.onChange(this.state.libraryModal.key, data.media_filename);
    this.toggleLibraryModal();
  }

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      return result;
    };

    const items = reorder(
      this.state.catalog.categories,
      result.source.index,
      result.destination.index
    );

    this.onChange("categories", items);
  };

  onDragEndAnnouncement = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      return result;
    };

    const items = reorder(
      this.state.catalog.catalog_announcements,
      result.source.index,
      result.destination.index
    );

    this.onChange("catalog_announcements", items);
  };

  onDragEndAnnouncementTicker = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      return result;
    };

    const items = reorder(
      this.state.catalog.catalog_announcementtickers,
      result.source.index,
      result.destination.index
    );

    this.onChange("catalog_announcementtickers", items);
  };

  removeCategory(id) {
    this.onChange(
      "categories",
      this.state.catalog.categories.filter((c) => c !== id)
    );
  }

  removeAnnouncement(id) {
    this.onChange(
      "catalog_announcements",
      this.state.catalog.catalog_announcements.filter((c) => c !== id)
    );
  }

  removeAnnouncementTicker(id) {
    this.onChange(
      "catalog_announcementtickers",
      this.state.catalog.catalog_announcementtickers.filter((c) => c !== id)
    );
  }

  render() {
    const errors = this.state.errors;

    if (this.state.prefetching) {
      return <Skeleton active />;
    }

    const selectedTypes = {
      stripe: false,
      razorpay: false,
      paypal: false,
    };

    this.state.catalog.catalog_paymentgateways.forEach((spg) => {
      const gateway = this.state.payment_gateways.find(
        (pg) => pg._id["$id"] === spg
      );
      if (gateway) {
        selectedTypes[gateway.type] = true;
      }
    });

    return (
      <div>
        <MainHeadingBar
          title={`${this.props.edit ? "Update" : "Create New"} Catalog`}
          {...this.props}
          cta={<div />}
        />
        <Card className="product-card">
          <h4>
            {this.props.edit
              ? "Let's Update Your Catalog!"
              : "Let's Create A New Catalog!"}
          </h4>

          <div className={`form-item-group ${errors.name ? "has-error" : ""}`}>
            <label className="ant-form-item-label">Catalog Name:</label>
            <Input
              placeholder="Enter the name of the catalog"
              value={this.state.catalog.name}
              onChange={(e) => this.onChange("name", e.target.value)}
              onBlur={(e) => this.onNameFieldBlur(e.target.value)}
            />
            {errors.name && <p className="error-label">{errors.name}</p>}
          </div>
          <div className={`form-item-group ${errors.slug ? "has-error" : ""}`}>
            <label className="ant-form-item-label">Catalog Slug:</label>
            <Input
              placeholder="Enter the slug for this catalog"
              value={this.state.catalog.slug}
              onChange={(e) => this.onChange("slug", e.target.value)}
            />
            {errors.slug && <p className="error-label">{errors.slug}</p>}
          </div>
          <div
            className={`form-item-group ${
              errors.internal_name ? "has-error" : ""
            }`}
          >
            <label className="ant-form-item-label">
              Catalog Internal Name:
            </label>
            <Input
              placeholder="Enter the internal name of the catalog"
              value={this.state.catalog.internal_name}
              onChange={(e) => this.onChange("internal_name", e.target.value)}
            />
            {errors.internal_name && (
              <p className="error-label">{errors.internal_name}</p>
            )}
          </div>
          <div className={`form-item-group`}>
            <label className="ant-form-item-label">
              Meta Title for SEO (Optional)
            </label>
            <Input
              placeholder="Enter the Meta Title of the catalog"
              value={this.state.catalog.meta_title}
              onChange={(e) => this.onChange("meta_title", e.target.value)}
            />
          </div>
          <div className={`form-item-group`}>
            <label className="ant-form-item-label">
              Meta Description for SEO (Optional)
            </label>
            <Input
              placeholder="Enter the Meta Description of the catalog"
              value={this.state.catalog.meta_desc}
              onChange={(e) => this.onChange("meta_desc", e.target.value)}
            />
          </div>
          <Row>
            <Col xs={12}>
              <div
                className={`form-item-group ${errors.logo ? "has-error" : ""}`}
              >
                <label className="ant-form-item-label">Catalog Logo:</label>

                <div className="upload-actions">
                  <div className="upload-action">
                    {this.state.catalog.logo && (
                      <img
                        alt="catalog logo"
                        src={`${CDN_URL}images/${this.state.catalog.logo}`}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    )}
                  </div>
                  <div
                    className="upload-action"
                    onClick={() => this.toggleLibraryModal("upload", "logo")}
                  >
                    <p>
                      <Icon type="upload" style={{ color: "#D9D9D9" }} /> <br />
                      Upload
                    </p>
                  </div>
                  <div
                    className="upload-action"
                    onClick={() => this.toggleLibraryModal("library", "logo")}
                  >
                    <p>
                      <Icon type="folder-open" style={{ color: "#D9D9D9" }} />{" "}
                      <br />
                      Library
                    </p>
                  </div>
                </div>
                {errors.logo && <p className="error-label">{errors.logo}</p>}
              </div>
            </Col>
            <Col xs={12}>
              <ColorPicker
                label="Catalog Theme Main Color:"
                defaultValue={this.state.catalog.maincolor}
                onChange={(color) => this.onChange("maincolor", color)}
              />
            </Col>
          </Row>
          <Row>
            <div
              className={`form-item-group ${errors.favicon ? "has-error" : ""}`}
            >
              <label className="ant-form-item-label">Catalog Favicon:</label>

              <div className="upload-actions">
                <div className="upload-action">
                  {this.state.catalog.favicon && (
                    <img
                      alt="catalog logo"
                      src={`${CDN_URL}images/${this.state.catalog.favicon}`}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  )}
                </div>
                <div
                  className="upload-action"
                  onClick={() => this.toggleLibraryModal("upload", "favicon")}
                >
                  <p>
                    <Icon type="upload" style={{ color: "#D9D9D9" }} /> <br />
                    Upload
                  </p>
                </div>
                <div
                  className="upload-action"
                  onClick={() => this.toggleLibraryModal("library", "favicon")}
                >
                  <p>
                    <Icon type="folder-open" style={{ color: "#D9D9D9" }} />{" "}
                    <br />
                    Library
                  </p>
                </div>
              </div>
              {errors.favicon && (
                <p className="error-label">{errors.favicon}</p>
              )}
            </div>
          </Row>
          <Row>
            <Col xs={24}>
              <div className={`form-item-group`}>
                <label className="ant-form-item-label">
                  Meta Image (Optional):
                </label>

                <div className="upload-actions">
                  <div className="upload-action">
                    {this.state.catalog.meta_image && (
                      <img
                        alt="catalog logo"
                        src={`${CDN_URL}images/${
                          this.state.catalog.meta_image
                        }`}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    )}
                  </div>
                  <div
                    className="upload-action"
                    onClick={() =>
                      this.toggleLibraryModal("upload", "meta_image")
                    }
                  >
                    <p>
                      <Icon type="upload" style={{ color: "#D9D9D9" }} /> <br />
                      Upload
                    </p>
                  </div>
                  <div
                    className="upload-action"
                    onClick={() =>
                      this.toggleLibraryModal("library", "meta_image")
                    }
                  >
                    <p>
                      <Icon type="folder-open" style={{ color: "#D9D9D9" }} />{" "}
                      <br />
                      Library
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <div className={`form-item-group`}>
            <label className="ant-form-item-label">
              Select Custom Domain (Optional):
            </label>
            <Select
              placeholder="Custom Domain"
              value={this.state.catalog.wlbldom}
              style={{ width: "100%" }}
              onChange={(val) => this.onChange("wlbldom", val)}
            >
              {this.state.wlbldoms.map((d) => (
                <Option key={d._id["$id"]} value={d._id["$id"]}>
                  {d.domain_name}
                </Option>
              ))}
            </Select>
          </div>
          <div className={`form-item-group`}>
            <label className="ant-form-item-label">
              Add & Re-order Categories:
            </label>
            <Select
              className="category-select"
              placeholder="Enter Categories"
              value={this.state.catalog.categories}
              style={{ width: "100%" }}
              mode="multiple"
              onChange={(val) => this.onChange("categories", val)}
            >
              {this.state.categories.map((c) => (
                <Option key={c._id["$id"]} value={c._id["$id"]}>
                  {c.name}
                </Option>
              ))}
            </Select>

            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {this.state.catalog.categories.map((cc, index) => {
                      const category = this.state.categories.find(
                        (c) => cc === c._id["$id"]
                      );
                      if (category) {
                        return (
                          <Draggable key={cc} draggableId={cc} index={index}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <div style={{ display: "flex" }}>
                                  <img
                                    style={{
                                      width: 50,
                                      height: 50,
                                      objectFit: "cover",
                                      border: "1px solid #ccc",
                                    }}
                                    src={`${CDN_URL}images/${category.icon}`}
                                    alt="category"
                                  />
                                  <div style={{ flex: 1, paddingLeft: 10 }}>
                                    <p
                                      style={{ fontWeight: "bold", margin: 0 }}
                                    >
                                      {category.name}
                                    </p>
                                  </div>
                                  <div>
                                    <a
                                      href="#!"
                                      onClick={() => this.removeCategory(cc)}
                                    >
                                      <Icon
                                        type="plus"
                                        style={{ transform: "rotate(45deg)" }}
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        );
                      }
                      return null;
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>

          <div className={`form-item-group`}>
            <label className="ant-form-item-label">
              Add & Re-order Announcements:
            </label>
            <Select
              className="category-select"
              placeholder="Select Announcements"
              value={this.state.catalog.catalog_announcements}
              style={{ width: "100%" }}
              mode="multiple"
              onChange={(val) => this.onChange("catalog_announcements", val)}
            >
              {this.state.announcements.map((c) => (
                <Option key={c._id["$id"]} value={c._id["$id"]}>
                  {c.announcement_name}
                </Option>
              ))}
            </Select>

            <DragDropContext onDragEnd={this.onDragEndAnnouncement}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {this.state.catalog.catalog_announcements.map(
                      (cc, index) => {
                        const announcement = this.state.announcements.find(
                          (c) => cc === c._id["$id"]
                        );
                        if (announcement) {
                          return (
                            <Draggable key={cc} draggableId={cc} index={index}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                >
                                  <div style={{ display: "flex" }}>
                                    <img
                                      style={{
                                        width: 50,
                                        height: 50,
                                        objectFit: "cover",
                                        border: "1px solid #ccc",
                                      }}
                                      src={`${CDN_URL}images/${
                                        announcement.announcement_image
                                      }`}
                                      alt="announcement"
                                    />
                                    <div style={{ flex: 1, paddingLeft: 10 }}>
                                      <p
                                        style={{
                                          fontWeight: "bold",
                                          margin: 0,
                                        }}
                                      >
                                        {announcement.announcement_name}
                                      </p>
                                    </div>
                                    <div>
                                      <a
                                        href="#!"
                                        onClick={() =>
                                          this.removeAnnouncement(cc)
                                        }
                                      >
                                        <Icon
                                          type="plus"
                                          style={{ transform: "rotate(45deg)" }}
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          );
                        }
                        return null;
                      }
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>

          <div className={`form-item-group`}>
            <label className="ant-form-item-label">
              Select & Re-arrange Announcement Tickers
            </label>
            <Select
              className="category-select"
              placeholder="Select Announcement Tickers"
              value={this.state.catalog.catalog_announcementtickers}
              style={{ width: "100%" }}
              mode="multiple"
              onChange={(val) =>
                this.onChange("catalog_announcementtickers", val)
              }
            >
              {this.state.announcement_tickers.map((c) => (
                <Option key={c._id["$id"]} value={c._id["$id"]}>
                  {c.announcement_name}
                </Option>
              ))}
            </Select>

            <DragDropContext onDragEnd={this.onDragEndAnnouncementTicker}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {this.state.catalog.catalog_announcementtickers.map(
                      (cc, index) => {
                        const announcement = this.state.announcement_tickers.find(
                          (c) => cc === c._id["$id"]
                        );
                        if (announcement) {
                          return (
                            <Draggable key={cc} draggableId={cc} index={index}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                >
                                  <div style={{ display: "flex" }}>
                                    <div style={{ flex: 1, paddingLeft: 10 }}>
                                      <p
                                        style={{
                                          fontWeight: "bold",
                                          margin: 0,
                                        }}
                                      >
                                        {announcement.announcement_name}
                                      </p>
                                    </div>
                                    <div>
                                      <a
                                        href="#!"
                                        onClick={() =>
                                          this.removeAnnouncementTicker(cc)
                                        }
                                      >
                                        <Icon
                                          type="plus"
                                          style={{ transform: "rotate(45deg)" }}
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          );
                        }
                        return null;
                      }
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>

          <div
            className={`form-item-group ${
              errors.catalog_paymentgateways ? "has-error" : ""
            }`}
          >
            <label className="ant-form-item-label">
              Select Payment Gateways:
            </label>
            <Select
              placeholder="Select Gateways"
              value={this.state.catalog.catalog_paymentgateways}
              style={{ width: "100%" }}
              mode="multiple"
              onChange={(val) => this.onChange("catalog_paymentgateways", val)}
            >
              {this.props.auth.plan.plan_features.COD === 1 && (
                <Option key="cash" value="cash">
                  Cash
                </Option>
              )}
              {this.state.payment_gateways.map((c) => {
                let disabled = false;
                if (
                  this.state.catalog.catalog_paymentgateways.indexOf(
                    c._id["$id"]
                  ) === -1
                ) {
                  const gateway = this.state.payment_gateways.find(
                    (pg) => pg._id["$id"] === c._id["$id"]
                  );
                  if (gateway && selectedTypes[gateway.type]) {
                    disabled = true;
                  }
                }

                return (
                  <Option
                    key={c._id["$id"]}
                    value={c._id["$id"]}
                    disabled={disabled}
                  >
                    {c.account_id} ({paymentGatewayTypes[c.type]})
                  </Option>
                );
              })}
            </Select>
            {errors.catalog_paymentgateways && (
              <p className="error-label">{errors.catalog_paymentgateways}</p>
            )}
          </div>

          <div style={{ display: "flex", marginTop: 30 }}>
            <Switch
              checked={this.state.catalog.pwa_enabled === 1}
              onChange={(checked) =>
                this.onChange("pwa_enabled", checked ? 1 : 0)
              }
              style={{ marginRight: 15 }}
            />{" "}
            PWA Installation is{" "}
            {this.state.catalog.pwa_enabled === 1 ? "Enabled" : "Disabled"}
          </div>

          <div style={{ display: "flex", margin: "30px 0" }}>
            <label>
              <Switch
                checked={this.state.catalog.translation_enabled === 1}
                onChange={(checked) =>
                  this.onChange("translation_enabled", checked ? 1 : 0)
                }
                style={{ marginRight: 15 }}
              />{" "}
              Catalog Translation is{" "}
              {this.state.catalog.translation_enabled === 1
                ? "Enabled"
                : "Disabled"}
            </label>
          </div>

          {this.state.catalog.translation_enabled === 1 && (
            <div
              className={`form-item-group ${
                errors.catalog_translationid ? "has-error" : ""
              }`}
            >
              <label className="ant-form-item-label">Select Translation:</label>
              <Select
                allowClear
                className="category-select"
                placeholder="Select Translation"
                value={this.state.catalog.catalog_translationid}
                style={{ width: "100%" }}
                onChange={(val) => this.onChange("catalog_translationid", val)}
              >
                {this.state.translations.map((c) => (
                  <Option key={c._id["$id"]} value={c._id["$id"]}>
                    {c.translation_name}
                  </Option>
                ))}
              </Select>
              {errors.catalog_translationid && (
                <p className="error-label">{errors.catalog_translationid}</p>
              )}
            </div>
          )}

          <div style={{ display: "flex", marginTop: 30, marginBottom: 10 }}>
            <Switch
              checked={this.state.catalog.catalog_socialsettings.enabled === 1}
              onChange={(checked) =>
                this.onChangeSocialSettings("enabled", checked ? 1 : 0)
              }
              style={{ marginRight: 15 }}
            />{" "}
            Social Settings{" "}
          </div>

          {this.state.catalog.catalog_socialsettings.enabled === 1 && (
            <>
              <div className={`form-item-group`}>
                <label className="ant-form-item-label">Description:</label>
                <TextArea
                  placeholder="Enter Description"
                  value={this.state.catalog.catalog_socialsettings.description}
                  onChange={(e) =>
                    this.onChangeSocialSettings("description", e.target.value)
                  }
                />
              </div>
              <div className={`form-item-group`}>
                <label className="ant-form-item-label">Facebook:</label>
                <Input
                  placeholder="Enter Facebook Link"
                  value={this.state.catalog.catalog_socialsettings.facebook}
                  onChange={(e) =>
                    this.onChangeSocialSettings("facebook", e.target.value)
                  }
                  onBlur={(e) => {
                    if (
                      e.target.value.trim().length &&
                      !/^https?:\/\//.test(e.target.value)
                    )
                      this.onChangeSocialSettings(
                        "facebook",
                        `https://${e.target.value}`
                      );
                  }}
                />
              </div>
              <div className={`form-item-group`}>
                <label className="ant-form-item-label">Twitter:</label>
                <Input
                  placeholder="Enter Twitter Link"
                  value={this.state.catalog.catalog_socialsettings.twitter}
                  onChange={(e) =>
                    this.onChangeSocialSettings("twitter", e.target.value)
                  }
                  onBlur={(e) => {
                    if (
                      e.target.value.trim().length &&
                      !/^https?:\/\//.test(e.target.value)
                    )
                      this.onChangeSocialSettings(
                        "twitter",
                        `https://${e.target.value}`
                      );
                  }}
                />
              </div>
              <div className={`form-item-group`}>
                <label className="ant-form-item-label">Instagram:</label>
                <Input
                  placeholder="Enter Instagram Link"
                  value={this.state.catalog.catalog_socialsettings.instagram}
                  onChange={(e) =>
                    this.onChangeSocialSettings("instagram", e.target.value)
                  }
                  onBlur={(e) => {
                    if (
                      e.target.value.trim().length &&
                      !/^https?:\/\//.test(e.target.value)
                    )
                      this.onChangeSocialSettings(
                        "instagram",
                        `https://${e.target.value}`
                      );
                  }}
                />
              </div>
              <div className={`form-item-group`}>
                <label className="ant-form-item-label">Whatspp:</label>
                <Input
                  placeholder="+11234567890"
                  value={this.state.catalog.catalog_socialsettings.whatsapp}
                  onChange={(e) =>
                    this.onChangeSocialSettings("whatsapp", e.target.value)
                  }
                />
              </div>
            </>
          )}

          <div style={{ display: "flex", marginTop: 30, marginBottom: 10 }}>
            <Switch
              checked={this.state.catalog.catalog_footerlinks.enabled === 1}
              onChange={(checked) => this.toggleFooterLinks(checked ? 1 : 0)}
              style={{ marginRight: 15 }}
            />{" "}
            Enable Footer Links{" "}
          </div>

          {this.state.catalog.catalog_footerlinks.enabled === 1 && (
            <>
              {this.state.catalog.catalog_footerlinks.links.map((link) => (
                <Row gutter={15} key={link.id}>
                  <Col xs={11}>
                    <div
                      className={`form-item-group ${
                        errors[`link_${link.id}_title`] ? "has-error" : ""
                      }`}
                    >
                      <label className="ant-form-item-label">Title:</label>
                      <Input
                        placeholder="Link Title"
                        value={link.title}
                        onChange={(e) =>
                          this.updateFooterLink(
                            link.id,
                            "title",
                            e.target.value
                          )
                        }
                      />
                      {errors[`link_${link.id}_title`] && (
                        <p className="error-label">
                          {errors[`link_${link.id}_title`]}
                        </p>
                      )}
                    </div>
                  </Col>
                  <Col xs={11}>
                    <div
                      className={`form-item-group ${
                        errors[`link_${link.id}_url`] ? "has-error" : ""
                      }`}
                    >
                      <label className="ant-form-item-label">URL:</label>
                      <Input
                        placeholder="Link URL"
                        value={link.url}
                        onChange={(e) =>
                          this.updateFooterLink(link.id, "url", e.target.value)
                        }
                        onBlur={(e) =>
                          this.updateFooterLink(
                            link.id,
                            "url",
                            e.target.value.match(/^https?:\/\//)
                              ? e.target.value
                              : `https://${e.target.value}`
                          )
                        }
                      />
                      {errors[`link_${link.id}_url`] && (
                        <p className="error-label">
                          {errors[`link_${link.id}_url`]}
                        </p>
                      )}
                    </div>
                  </Col>
                  <Col xs={2}>
                    <div>
                      <a
                        href="#!"
                        onClick={() => this.removeFooterLink(link.id)}
                      >
                        <Icon type="close" />
                      </a>
                    </div>
                  </Col>
                </Row>
              ))}
              <Row>
                <a href="#!" onClick={() => this.addFooterLink()}>
                  Add Link
                </a>
              </Row>
            </>
          )}

          <div style={{ display: "flex", marginTop: 30, marginBottom: 10 }}>
            <Switch
              checked={this.state.catalog.catalog_dinein.enabled === 1}
              onChange={(checked) =>
                this.onChangeDineIn("enabled", checked ? 1 : 0)
              }
              style={{ marginRight: 15 }}
            />{" "}
            Enable Dine In{" "}
          </div>

          {this.state.catalog.catalog_dinein.enabled === 1 ? (
            <div
              style={{
                padding: 10,
                background: "#fafafa",
                border: "1px solid #f1f1f2",
                borderRadius: 4,
              }}
            >
              <div
                className={`form-item-group ${
                  errors.dinein_days ? "has-error" : ""
                }`}
              >
                <label className="ant-form-item-label">Select Days:</label>
                <Select
                  allowClear
                  placeholder="Select Delivery Days"
                  mode="multiple"
                  value={this.state.catalog.catalog_dinein.days}
                  style={{ width: "100%" }}
                  onChange={(val) => this.onChangeDineIn("days", val)}
                >
                  <Option value={1}>Monday</Option>
                  <Option value={2}>Tuesday</Option>
                  <Option value={3}>Wednesday</Option>
                  <Option value={4}>Thursday</Option>
                  <Option value={5}>Friday</Option>
                  <Option value={6}>Saturday</Option>
                  <Option value={7}>Sunday</Option>
                </Select>
                {errors.dinein_days && (
                  <p className="error-label">{errors.dinein_days}</p>
                )}
              </div>

              <div
                className={`form-item-group ${
                  errors.dinein_timing ? "has-error" : ""
                }`}
              >
                <label className="ant-form-item-label">
                  Select Dinein Timings:
                </label>
                {this.state.catalog.catalog_dinein.timings.map((t, idx) => (
                  <div style={{ marginBottom: 15 }}>
                    <TimePicker
                      value={t.start ? moment(t.start, "HH:mm") : undefined}
                      format="HH:mm"
                      onChange={(val) =>
                        this.updateCatalogTiming(
                          "catalog_dinein",
                          idx,
                          "start",
                          isMoment(val) ? val.format("HH:mm") : ""
                        )
                      }
                    />
                    <span style={{ marginLeft: 10, marginRight: 10 }}>to</span>
                    <TimePicker
                      value={t.end ? moment(t.end, "HH:mm") : undefined}
                      format="HH:mm"
                      onChange={(val) =>
                        this.updateCatalogTiming(
                          "catalog_dinein",
                          idx,
                          "end",
                          isMoment(val) ? val.format("HH:mm") : ""
                        )
                      }
                    />
                    {this.state.catalog.catalog_dinein.timings.length > 1 ? (
                      <span style={{ marginLeft: 10 }}>
                        <a
                          href="#!"
                          onClick={() =>
                            this.removeCatalogTiming("catalog_dinein", idx)
                          }
                        >
                          <Icon type="close" />
                        </a>
                      </span>
                    ) : null}
                    {errors[`dinein_timing_${idx}`] && (
                      <p className="error-label" style={{ color: "red" }}>
                        {errors[`dinein_timing_${idx}`]}
                      </p>
                    )}
                  </div>
                ))}
                {errors[`dinein_timing`] && (
                  <p className="error-label">{errors[`dinein_timing`]}</p>
                )}
                <a
                  href="#!"
                  onClick={() => this.addCatalogTiming("catalog_dinein")}
                >
                  Add Another Time
                </a>
              </div>
            </div>
          ) : null}

          {this.state.catalog.catalog_dinein.enabled === 1 ? (
            <>
              <div style={{ display: "flex", marginTop: 30, marginBottom: 10 }}>
                <Switch
                  checked={this.state.catalog.catalog_tips.enabled === 1}
                  onChange={(checked) =>
                    this.onChangeTips("enabled", checked ? 1 : 0)
                  }
                  style={{ marginRight: 15 }}
                />{" "}
                Enable Tips{" "}
              </div>

              {this.state.catalog.catalog_tips.enabled === 1 && (
                <>
                  <div
                    style={{ display: "flex", marginTop: 30, marginBottom: 10 }}
                  >
                    <Switch
                      checked={this.state.catalog.catalog_tips.required === 1}
                      onChange={(checked) =>
                        this.onChangeTips("required", checked ? 1 : 0)
                      }
                      style={{ marginRight: 15 }}
                    />{" "}
                    Make Tips Compulsory
                  </div>

                  <div className={`form-item-group`}>
                    <label className="ant-form-item-label">Type:</label>
                    <Select
                      allowClear
                      className="category-select"
                      placeholder="Select"
                      value={this.state.catalog.catalog_tips.type}
                      style={{ width: "100%" }}
                      onChange={(val) => this.onChangeTips("type", val)}
                    >
                      <Option value="percentage">Percentage</Option>
                      <Option value="fixed">Fixed Values</Option>
                    </Select>
                  </div>

                  {this.state.catalog.catalog_tips.type === "percentage" ? (
                    <div
                      className={`form-item-group ${
                        errors.tips_percentage ? "has-error" : ""
                      }`}
                    >
                      <label className="ant-form-item-label">
                        Tip Percentage:
                      </label>
                      <Input
                        placeholder="Enter Percentage"
                        value={this.state.catalog.catalog_tips.tips_percentage}
                        onChange={(e) =>
                          this.onChangeTips("tips_percentage", e.target.value)
                        }
                      />
                      {errors.tips_percentage && (
                        <p className="error-label">{errors.tips_percentage}</p>
                      )}
                    </div>
                  ) : (
                    <Row gutter={15}>
                      {[0, 1, 2, 3].map((idx) => (
                        <Col xs={6}>
                          <div className={`form-item-group`}>
                            <label className="ant-form-item-label">
                              Tip Option {idx + 1}:
                            </label>
                            <Input
                              placeholder="Tip Amount"
                              value={
                                this.state.catalog.catalog_tips.tips[idx].amount
                              }
                              style={{
                                width: "100%",
                                display: "inline-block",
                              }}
                              onChange={(e) => {
                                const tips = JSON.parse(
                                  JSON.stringify(
                                    this.state.catalog.catalog_tips.tips
                                  )
                                );
                                tips[idx]["amount"] = e.target.value.replace(
                                  /[^\d.]/,
                                  ""
                                );
                                this.onChangeTips("tips", tips);
                              }}
                            />
                          </div>
                        </Col>
                      ))}
                      <div
                        className={`form-item-group ${
                          errors.tips_custom ? "has-error" : ""
                        }`}
                      >
                        {errors.tips_custom && (
                          <p className="error-label">{errors.tips_custom}</p>
                        )}
                      </div>
                    </Row>
                  )}
                </>
              )}
            </>
          ) : null}

          <div style={{ display: "flex", marginTop: 30, marginBottom: 10 }}>
            <Switch
              checked={this.state.catalog.catalog_takeaway.enabled === 1}
              onChange={(checked) =>
                this.onChangeTakeaway("enabled", checked ? 1 : 0)
              }
              style={{ marginRight: 15 }}
            />{" "}
            Enable Takeaway{" "}
          </div>

          {this.state.catalog.catalog_takeaway.enabled === 1 ? (
            <div
              style={{
                padding: 10,
                background: "#fafafa",
                border: "1px solid #f1f1f2",
                borderRadius: 4,
              }}
            >
              <div
                className={`form-item-group ${
                  errors.takeaway_days ? "has-error" : ""
                }`}
              >
                <label className="ant-form-item-label">Select Days:</label>
                <Select
                  allowClear
                  placeholder="Select Delivery Days"
                  mode="multiple"
                  value={this.state.catalog.catalog_takeaway.days}
                  style={{ width: "100%" }}
                  onChange={(val) => this.onChangeTakeaway("days", val)}
                >
                  <Option value={1}>Monday</Option>
                  <Option value={2}>Tuesday</Option>
                  <Option value={3}>Wednesday</Option>
                  <Option value={4}>Thursday</Option>
                  <Option value={5}>Friday</Option>
                  <Option value={6}>Saturday</Option>
                  <Option value={7}>Sunday</Option>
                </Select>
                {errors.takeaway_days && (
                  <p className="error-label">{errors.takeaway_days}</p>
                )}
              </div>

              <div
                className={`form-item-group ${
                  errors.takeaway_timing ? "has-error" : ""
                }`}
              >
                <label className="ant-form-item-label">
                  Select Takeaway Timings:
                </label>
                {this.state.catalog.catalog_takeaway.timings.map((t, idx) => (
                  <div style={{ marginBottom: 15 }}>
                    <TimePicker
                      value={t.start ? moment(t.start, "HH:mm") : undefined}
                      format="HH:mm"
                      onChange={(val) =>
                        this.updateCatalogTiming(
                          "catalog_takeaway",
                          idx,
                          "start",
                          isMoment(val) ? val.format("HH:mm") : ""
                        )
                      }
                    />
                    <span style={{ marginLeft: 10, marginRight: 10 }}>to</span>
                    <TimePicker
                      value={t.end ? moment(t.end, "HH:mm") : undefined}
                      format="HH:mm"
                      onChange={(val) =>
                        this.updateCatalogTiming(
                          "catalog_takeaway",
                          idx,
                          "end",
                          isMoment(val) ? val.format("HH:mm") : ""
                        )
                      }
                    />
                    {this.state.catalog.catalog_takeaway.timings.length > 1 ? (
                      <span style={{ marginLeft: 10 }}>
                        <a
                          href="#!"
                          onClick={() =>
                            this.removeCatalogTiming("catalog_takeaway", idx)
                          }
                        >
                          <Icon type="close" />
                        </a>
                      </span>
                    ) : null}
                    {errors[`takeaway_timing_${idx}`] && (
                      <p className="error-label" style={{ color: "red" }}>
                        {errors[`takeaway_timing_${idx}`]}
                      </p>
                    )}
                  </div>
                ))}
                {errors[`takeaway_timing`] && (
                  <p className="error-label">{errors[`takeaway_timing`]}</p>
                )}
                <a
                  href="#!"
                  onClick={() => this.addCatalogTiming("catalog_takeaway")}
                >
                  Add Another Time
                </a>
              </div>
            </div>
          ) : null}

          <div style={{ display: "flex", marginTop: 30, marginBottom: 10 }}>
            <Switch
              checked={this.state.catalog.catalog_delivery.enabled === 1}
              onChange={(checked) =>
                this.onChangeDelivery("enabled", checked ? 1 : 0)
              }
              style={{ marginRight: 15 }}
            />{" "}
            Enable Delivery{" "}
          </div>

          {this.state.catalog.catalog_delivery.enabled === 1 ? (
            <div
              style={{
                padding: 10,
                background: "#fafafa",
                border: "1px solid #f1f1f2",
                borderRadius: 4,
              }}
            >
              <div
                className={`form-item-group ${
                  errors.delivery_charge ? "has-error" : ""
                }`}
              >
                <label className="ant-form-item-label">Delivery Charges:</label>
                <Input
                  placeholder="Enter Delivery Charge"
                  value={this.state.catalog.catalog_delivery.amount}
                  onChange={(e) =>
                    this.onChangeDelivery(
                      "amount",
                      e.target.value.replace(/[^\d.]/, "")
                    )
                  }
                />
                {errors.delivery_charge && (
                  <p className="error-label">{errors.delivery_charge}</p>
                )}
              </div>

              <div
                className={`form-item-group ${
                  errors.delivery_days ? "has-error" : ""
                }`}
              >
                <label className="ant-form-item-label">Select Days:</label>
                <Select
                  allowClear
                  placeholder="Select Delivery Days"
                  mode="multiple"
                  value={this.state.catalog.catalog_delivery.days}
                  style={{ width: "100%" }}
                  onChange={(val) => this.onChangeDelivery("days", val)}
                >
                  <Option value={1}>Monday</Option>
                  <Option value={2}>Tuesday</Option>
                  <Option value={3}>Wednesday</Option>
                  <Option value={4}>Thursday</Option>
                  <Option value={5}>Friday</Option>
                  <Option value={6}>Saturday</Option>
                  <Option value={7}>Sunday</Option>
                </Select>
                {errors.delivery_days && (
                  <p className="error-label">{errors.delivery_days}</p>
                )}
              </div>

              <div
                className={`form-item-group ${
                  errors.delivery_timing ? "has-error" : ""
                }`}
              >
                <label className="ant-form-item-label">
                  Select Delivery Timings:
                </label>
                {this.state.catalog.catalog_delivery.timings.map((t, idx) => (
                  <div style={{ marginBottom: 15 }}>
                    <TimePicker
                      value={t.start ? moment(t.start, "HH:mm") : undefined}
                      format="HH:mm"
                      onChange={(val) =>
                        this.updateCatalogTiming(
                          "catalog_delivery",
                          idx,
                          "start",
                          isMoment(val) ? val.format("HH:mm") : ""
                        )
                      }
                    />
                    <span style={{ marginLeft: 10, marginRight: 10 }}>to</span>
                    <TimePicker
                      value={t.end ? moment(t.end, "HH:mm") : undefined}
                      format="HH:mm"
                      onChange={(val) =>
                        this.updateCatalogTiming(
                          "catalog_delivery",
                          idx,
                          "end",
                          isMoment(val) ? val.format("HH:mm") : ""
                        )
                      }
                    />
                    {this.state.catalog.catalog_delivery.timings.length > 1 ? (
                      <span style={{ marginLeft: 10 }}>
                        <a
                          href="#!"
                          onClick={() =>
                            this.removeCatalogTiming("catalog_delivery", idx)
                          }
                        >
                          <Icon type="close" />
                        </a>
                      </span>
                    ) : null}
                    {errors[`delivery_timing_${idx}`] && (
                      <p className="error-label" style={{ color: "red" }}>
                        {errors[`delivery_timing_${idx}`]}
                      </p>
                    )}
                  </div>
                ))}
                {errors[`delivery_timing`] && (
                  <p className="error-label">{errors[`delivery_timing`]}</p>
                )}
                <a
                  href="#!"
                  onClick={() => this.addCatalogTiming("catalog_delivery")}
                >
                  Add Another Time
                </a>
              </div>
            </div>
          ) : null}

          <div className={`form-item-group`}>
            <label className="ant-form-item-label">
              Order Page Widget Pixel:
            </label>
            <TextArea
              value={this.state.catalog.orderpage_pixel}
              onChange={(e) => this.onChange("orderpage_pixel", e.target.value)}
            />
          </div>

          {errors.form_error && (
            <div className={`form-item-group has-error`}>
              <p className="error-label">{errors.form_error}</p>
            </div>
          )}
          <div>
            <Button
              className="footer-button"
              onClick={() => this.onSubmit()}
              loading={this.state.submitting}
            >
              {this.props.edit ? "" : "Create &"} Save Catalog
            </Button>
          </div>
        </Card>
        <LibraryModal
          {...this.props}
          onCancel={() => this.toggleLibraryModal()}
          tab={this.state.libraryModal.tab}
          visible={this.state.libraryModal.visible}
          onChoose={(data) => this.onChoose(data)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth.auth,
});
export default connect(mapStateToProps)(CreateCatalog);
