import React, { Component } from "react";
import "./index.scss";
import {
  Button,
  Divider,
  Row,
  Col,
  Input,
  Icon,
  Skeleton,
  Alert,
  Switch,
  message,
  Tooltip,
} from "antd";
import { connect } from "react-redux";
import axios from "axios";

class Integrations extends Component {
  state = {
    error: false,
    errorMsg: "",
    loading: false,
    saving: false,
    domain: {
      dom_domain: "",
      dom_name: "",
      dom_logo: "",
      dom_themecolor: "",
      dom_orderasguest: 0,
      dom_twilio: {
        enabled: 0,
        account_id: "",
        auth_token: "",
        from_phone: "",
      },
    },
    libraryModal: {
      visible: false,
      tab: "upload",
    },
  };

  toggleLibraryModal(tab = "upload") {
    this.setState((state) => ({
      libraryModal: {
        ...state.libraryModal,
        visible: !state.libraryModal.visible,
        tab,
      },
    }));
  }

  onChange = (k, v) => {
    this.setState((state) => ({
      domain: {
        ...state.domain,
        [k]: v,
      },
    }));
  };

  onChangeTwilio = (k, v) => {
    if (this.props.auth.plan.plan_features.TWILIO !== 1) {
      message.error("Your plan does not allow you to access this feature!");
      return;
    }
    this.setState((state) => ({
      domain: {
        ...state.domain,
        dom_twilio: {
          ...state.domain.dom_twilio,
          [k]: v,
        },
      },
    }));
  };

  onChoose(data) {
    this.onChange("dom_logo", data.media_filename);
    this.toggleLibraryModal();
  }

  fetchSettings() {
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url: "/zerotouch/get_store_settings",
          method: "post",
          data: {
            domid: this.props.match.params.domid,
          },
        })
          .then((res) => {
            this.setState({ loading: false });
            return res;
          })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                domain: {
                  ...this.state.domain,
                  ...res.data.data,
                },
              });
            } else {
              this.setState({
                error: true,
                errorMsg: res.data.msg,
              });
            }
          })
          .catch((err) => {
            this.setState({
              error: true,
              errorMsg: "There was an error!",
            });
            console.log(err);
          });
      }
    );
  }

  saveSettings() {
    this.setState(
      {
        saving: true,
      },
      () => {
        axios({
          url: "/zerotouch/save_store_settings",
          method: "post",
          data: {
            domid: this.props.match.params.domid,
            data: this.state.domain,
          },
        })
          .then((res) => {
            this.setState({ saving: false });
            return res;
          })
          .then((res) => {
            if (res.data.success === 1) {
              message.success("Settings Saved Successfully!");
            } else {
              message.error(res.data.msg);
            }
          })
          .catch((err) => {
            message.error("There was an error!");
            console.log(err);
          });
      }
    );
  }

  componentDidMount = () => {
    this.fetchSettings();
  };

  render() {
    const { error, errorMsg, loading } = this.state;
    if (loading) {
      return (
        <div style={{ marginBottom: "30px" }}>
          <Skeleton active />
        </div>
      );
    }

    if (!loading && error) {
      return (
        <div>
          <Row>
            <Alert
              message="There was an error"
              description={errorMsg}
              type="error"
            />
          </Row>
        </div>
      );
    }
    const domain = this.state.domain;
    return (
      <div className="account-settings">
        <h3 className="heading">
          <span className="text">
            Integrations{" "}
            <Tooltip title="Here you can manage the settings of your account ">
              <Icon type="info-circle" style={{ fontSize: "1.5rem" }} />
            </Tooltip>
          </span>
          <Button
            type="default"
            onClick={() => this.saveSettings()}
            loading={this.state.saving}
          >
            Save Settings
          </Button>
        </h3>

        <Divider />

        <Row>
          <div className="input-container">
            <p className="label">
              Enable Twilio:{" "}
              <Switch
                checked={domain.dom_twilio.enabled === 1}
                onChange={(checked) =>
                  this.onChangeTwilio("enabled", Number(checked))
                }
              />
            </p>
          </div>
        </Row>
        {this.state.domain.dom_twilio.enabled === 1 && (
          <Row gutter={15}>
            <Col xs={8}>
              <div className="input-container">
                <p className="label">Twilio Account ID:</p>
                <Input
                  value={domain.dom_twilio.account_id}
                  onChange={(e) =>
                    this.onChangeTwilio("account_id", e.target.value)
                  }
                />
              </div>
            </Col>
            <Col xs={8}>
              <div className="input-container">
                <p className="label">Twilio Auth Token:</p>
                <Input
                  value={domain.dom_twilio.auth_token}
                  onChange={(e) =>
                    this.onChangeTwilio("auth_token", e.target.value)
                  }
                />
              </div>
            </Col>
            <Col xs={8}>
              <div className="input-container">
                <p className="label">Twilio From Phone Number:</p>
                <Input
                  value={domain.dom_twilio.from_phone}
                  onChange={(e) =>
                    this.onChangeTwilio("from_phone", e.target.value)
                  }
                />
              </div>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};

export default connect(mapStateToProps)(Integrations);
