import React, { Component } from "react";
import "./index.scss";
import {
  Button,
  Divider,
  Row,
  Col,
  Input,
  Icon,
  Skeleton,
  Alert,
  message,
  Tooltip,
  Switch,
  Select,
} from "antd";
import { CDN_URL } from "../../../../config";
import LibraryModal from "../../Library/LibraryModal";
import ColorPicker from "../../ColorPicker";
import { connect } from "react-redux";
import axios from "axios";
import moment from "moment-timezone";

class AccountSettings extends Component {
  state = {
    error: false,
    errorMsg: "",
    loading: false,
    saving: false,
    domain: {
      dom_domain: "",
      dom_name: "",
      dom_logo: "",
      dom_themecolor: "",
      dom_orderasguest: 0,
      dom_timezone: "UTC",
      dom_twilio: {
        enabled: 0,
        account_id: "",
        auth_token: "",
        from_phone: "",
      },
    },
    libraryModal: {
      visible: false,
      tab: "upload",
    },
  };

  toggleLibraryModal(tab = "upload") {
    this.setState((state) => ({
      libraryModal: {
        ...state.libraryModal,
        visible: !state.libraryModal.visible,
        tab,
      },
    }));
  }

  onChange = (k, v) => {
    if (
      k === "dom_orderasguest" &&
      this.props.auth.plan.plan_features.ORDER_AS_GUEST !== 1
    ) {
      message.error("Your plan does not allow you to access this feature!");
      return;
    }
    this.setState((state) => ({
      domain: {
        ...state.domain,
        [k]: v,
      },
    }));
  };

  onChangeTwilio = (k, v) => {
    this.setState((state) => ({
      domain: {
        ...state.domain,
        dom_twilio: {
          ...state.domain.dom_twilio,
          [k]: v,
        },
      },
    }));
  };

  onChoose(data) {
    this.onChange("dom_logo", data.media_filename);
    this.toggleLibraryModal();
  }

  fetchSettings() {
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url: "/zerotouch/get_store_settings",
          method: "post",
          data: {
            domid: this.props.match.params.domid,
          },
        })
          .then((res) => {
            this.setState({ loading: false });
            return res;
          })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                domain: {
                  ...this.state.domain,
                  ...res.data.data,
                },
              });
            } else {
              this.setState({
                error: true,
                errorMsg: res.data.msg,
              });
            }
          })
          .catch((err) => {
            this.setState({
              error: true,
              errorMsg: "There was an error!",
            });
            console.log(err);
          });
      }
    );
  }

  saveSettings() {
    this.setState(
      {
        saving: true,
      },
      () => {
        axios({
          url: "/zerotouch/save_store_settings",
          method: "post",
          data: {
            domid: this.props.match.params.domid,
            data: this.state.domain,
          },
        })
          .then((res) => {
            this.setState({ saving: false });
            return res;
          })
          .then((res) => {
            if (res.data.success === 1) {
              message.success("Settings Saved Successfully!");
            } else {
              message.error(res.data.msg);
            }
          })
          .catch((err) => {
            message.error("There was an error!");
            console.log(err);
          });
      }
    );
  }

  componentDidMount = () => {
    this.fetchSettings();
  };

  render() {
    const { error, errorMsg, loading } = this.state;
    if (loading) {
      return (
        <div style={{ marginBottom: "30px" }}>
          <Skeleton active />
        </div>
      );
    }

    if (!loading && error) {
      return (
        <div>
          <Row>
            <Alert
              message="There was an error"
              description={errorMsg}
              type="error"
            />
          </Row>
        </div>
      );
    }
    const domain = this.state.domain;
    return (
      <div className="account-settings">
        <h3 className="heading">
          <span className="text">
            Basic Settings{" "}
            <Tooltip title="Here you can manage the settings of your account ">
              <Icon type="info-circle" style={{ fontSize: "1.5rem" }} />
            </Tooltip>
          </span>
          <Button
            type="default"
            onClick={() => this.saveSettings()}
            loading={this.state.saving}
          >
            Save Settings
          </Button>
        </h3>

        <Divider />

        <Row gutter={24}>
          <Col xs={24}>
            <div className="input-container">
              <p className="label">Subdomain/Domain:</p>
              <Input
                disabled={true}
                value={`${domain.dom_domain}.zerotouch.agency`}
              />
            </div>

            <div className="input-container">
              <p className="label">Select Logo:</p>
              <div className="upload-actions">
                {domain.dom_logo && (
                  <img
                    alt="store logo"
                    src={`${CDN_URL}images/${domain.dom_logo}`}
                    style={{
                      maxWidth: "7.14rem",
                      marginRight: 10,
                      border: "1px solid #ccc",
                    }}
                  />
                )}
                <div
                  className="upload-action"
                  onClick={() => this.toggleLibraryModal("upload")}
                >
                  <p>
                    <Icon type="upload" style={{ color: "#D9D9D9" }} /> <br />
                    Upload
                  </p>
                </div>
                <div
                  className="upload-action"
                  onClick={() => this.toggleLibraryModal("library")}
                >
                  <p>
                    <Icon type="folder-open" style={{ color: "#D9D9D9" }} />{" "}
                    <br />
                    Library
                  </p>
                </div>
              </div>
            </div>

            <div className="input-container">
              <p className="label">Select Brand Name:</p>
              <Input
                value={domain.dom_name}
                onChange={(e) => this.onChange("dom_name", e.target.value)}
              />
            </div>

            <div className="input-container">
              <p className="label">Select Timezone:</p>
              <Select
                style={{ width: "100%" }}
                showSearch
                filterOption={true}
                value={domain.dom_timezone}
                onChange={(val) => this.onChange("dom_timezone", val)}
              >
                {moment.tz.names().map((tz) => (
                  <Select.Option value={tz} key={tz}>
                    {tz}
                  </Select.Option>
                ))}
              </Select>
            </div>

            <div style={{ display: "flex" }}>
              <div style={{ display: "flex", flex: 1, alignItems: "center" }}>
                <label>
                  <Switch
                    checked={domain.dom_orderasguest === 1}
                    style={{ color: "green" }}
                    onChange={(checked) =>
                      this.onChange("dom_orderasguest", Number(checked))
                    }
                  />{" "}
                  Order As Guest Is{" "}
                  {domain.dom_orderasguest === 1 ? "Enabled" : "Disabled"}
                </label>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  alignItems: "center",
                  paddingLeft: 10,
                }}
                className="input-container"
              >
                <p
                  className="label"
                  style={{ display: "inline-block", margin: 0 }}
                >
                  Base Color Theme
                </p>
                <ColorPicker
                  label=""
                  defaultValue={domain.dom_themecolor}
                  onChange={(color) => this.onChange("dom_themecolor", color)}
                  style={{ display: "inline-block", margin: "0 0 0 10px" }}
                />
              </div>
            </div>
          </Col>
        </Row>

        <LibraryModal
          {...this.props}
          tab={this.state.libraryModal.tab}
          onCancel={() => this.toggleLibraryModal()}
          visible={this.state.libraryModal.visible}
          onChoose={(data) => this.onChoose(data)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};

export default connect(mapStateToProps)(AccountSettings);
