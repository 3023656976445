import React, { Component } from "react";
import MainHeadingBar from "../Partials/MainHeadingBar";
import {
  Row,
  Skeleton,
  Alert,
  Button,
  Dropdown,
  Icon,
  Menu,
  Card,
  Table,
} from "antd";
import { connect } from "react-redux";
import "./index.scss";
import Axios from "axios";
import CreateStationModal from "./CreateStationModal";
import { Redirect } from "react-router-dom";
const { SubMenu } = Menu;

class Stations extends Component {
  state = {
    loading: false,
    error: false,
    errorMsg: "",
    stations: [],
    paging: {
      page: 1,
      total: 0,
      pageSize: 10,
      order: -1,
    },
    catalogs: [],
    stationModal: {
      visible: false,
      data: {},
    },
  };

  toggleStationModal(data = {}) {
    this.setState({
      stationModal: {
        visible: !this.state.stationModal.visible,
        data,
      },
    });
  }

  fetchStations() {
    this.setState(
      {
        loading: true,
      },
      () => {
        Axios({
          url: "/zerotouch/get_stations",
          method: "post",
          data: {
            page: this.state.paging.page,
            pageSize: this.state.paging.pageSize,
            order: this.state.paging.order,
            domid: this.props.match.params.domid,
          },
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                loading: false,
                stations: res.data.data,
                paging: { ...this.state.paging, total: res.data.total },
                sbdom: res.data.sbdom,
                wlbldom: res.data.wlbldom,
              });
            } else {
              this.setState({
                loading: false,
                error: true,
                errorMsg: res.data.msg,
              });
            }
          })
          .catch((err) => {
            this.setState({
              loading: false,
              error: true,
              errorMsg: err.message,
            });
          });
      }
    );
  }

  fetchCatalogs() {
    Axios({
      url: "/zerotouch/get_catalog",
      method: "post",
      data: {
        page: 1,
        pageSize: 100000,
        domid: this.props.match.params.domid,
      },
    })
      .then((res) => {
        if (res.data.success === 1) {
          this.setState({
            catalogs: res.data.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentDidMount = () => {
    this.fetchStations();
    this.fetchCatalogs();
  };

  goToPage(pagination) {
    this.setState(
      {
        paging: {
          ...this.state.paging,
          page: pagination.current,
        },
      },
      () => this.fetchStations()
    );
  }

  setSortOrder(order) {
    this.setState(
      {
        paging: {
          ...this.state.paging,
          order: order === "asc" ? 1 : -1,
        },
      },
      () => this.fetchStation()
    );
  }

  render() {
    const { loading, error, errorMsg } = this.state;
    if (this.props.auth.domain.role === "store_user") {
      return <Redirect to={`/${this.props.auth.domain.id}/orders`} />;
    }
    const sortMenu = (
      <Menu>
        <Menu.Item key={0}>
          <a href="#/" onClick={() => this.setSortOrder("asc")}>
            Asc. Order
          </a>
        </Menu.Item>
        <Menu.Item key={1}>
          <a href="#/" onClick={() => this.setSortOrder("desc")}>
            Desc. Order
          </a>
        </Menu.Item>
      </Menu>
    );

    const PageWrapper = (children) => (
      <div className="categories-container">
        <MainHeadingBar
          title="Stations"
          pageCTA={
            <span className="sort">
              Sort By Date:{" "}
              <Dropdown overlay={sortMenu}>
                <Button size="default">
                  {this.state.paging.order === 1 ? "Asc." : "Desc."} Order{" "}
                  <Icon type="down" />
                </Button>
              </Dropdown>
            </span>
          }
          cta={
            <>
              <div className="actions">
                <Button size="large" onClick={() => this.toggleStationModal()}>
                  Create New Station
                </Button>
              </div>
            </>
          }
          {...this.props}
        />
        {children}
        <CreateStationModal
          {...this.props}
          reloadList={() => this.fetchStations()}
          toggleStationModal={() => this.toggleStationModal()}
          visible={this.state.stationModal.visible}
          data={this.state.stationModal.data}
        />
      </div>
    );

    if (loading) {
      return PageWrapper(
        <Card style={{ marginBottom: "30px" }}>
          <Skeleton active />
        </Card>
      );
    }

    if (!loading && error) {
      return PageWrapper(
        <div>
          <Row>
            <Alert
              message="There was an error"
              description={errorMsg}
              type="error"
            />
          </Row>
        </div>
      );
    }
    if (!loading && !error && !this.state.stations.length > 0) {
      return PageWrapper(
        <Card>
          <div className="campaign-empty-state" style={{ textAlign: "center" }}>
            <h3>Here for the first time?</h3>
            <p>Looks like you have not created any stations yet. </p>
            <p>No worries! We've got you covered! </p>
            {this.props.auth.domain.role !== "analyst" && (
              <Button
                type="primary"
                className="red-button"
                onClick={() => this.toggleStationModal()}
              >
                Create New Station
              </Button>
            )}
          </div>
        </Card>
      );
    }
    const columns = [
      {
        title: "Station Name",
        dataIndex: "station_name",
      },
      {
        title: "Station Code",
        dataIndex: "station_code",
      },
      {
        title: "Actions",
        dataIndex: null,
        render: (_, row) => {
          let baseURL = this.state.wlbldom
            ? `https://${this.state.wlbldom}`
            : `https://${this.state.sbdom}.zerotouch.agency`;

          return (
            <div className="category-actions">
              <Dropdown
                overlay={
                  <Menu>
                    {this.state.catalogs.map((c) => {
                      return (
                        <Menu.Item key={c.name}>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`${baseURL}/${c.slug}?station=${
                              row.station_code
                            }`}
                          >
                            {c.name}
                          </a>
                        </Menu.Item>
                      );
                    })}
                  </Menu>
                }
              >
                <a
                  onClick={(e) => e.preventDefault()}
                  href="#!"
                  className="ant-btn ant-btn-primary"
                >
                  Station Link
                </a>
              </Dropdown>{" "}
              <Dropdown
                overlay={
                  <Menu>
                    {this.state.catalogs.map((c) => {
                      return (
                        <SubMenu title={c.name}>
                          {[1, 2, 3, 4, 5].map((template) => (
                            <Menu.Item key={template}>
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`/printables/print_qr_code?domid=${
                                  this.props.match.params.domid
                                }&station=${row.station_id}&catalog=${
                                  c.slug
                                }&template=template${template}&lang=${
                                  c.translation_enabled === 1
                                    ? c.catalog_translationid["$id"]
                                    : "en"
                                }`}
                              >
                                Template {template}
                              </a>
                            </Menu.Item>
                          ))}
                        </SubMenu>
                      );
                    })}
                  </Menu>
                }
              >
                <a
                  onClick={(e) => e.preventDefault()}
                  href="#!"
                  className="ant-btn ant-btn-primary"
                >
                  View QR Code
                </a>
              </Dropdown>{" "}
              {this.props.auth.domain.role !== "analyst" && (
                <Button
                  type="primary"
                  onClick={() =>
                    this.toggleStationModal({
                      station_name: row.station_name,
                      station_code: row.station_code,
                      edit: row._id["$id"],
                    })
                  }
                >
                  Edit
                </Button>
              )}
            </div>
          );
        },
      },
    ];

    const pagination = {
      current: this.state.paging.page,
      pageSize: this.state.paging.pageSize,
      total: this.state.paging.total,
    };

    return PageWrapper(
      <div className="category-list">
        <Row gutter={30}>
          <Table
            columns={columns}
            rowKey={(_, i) => i}
            dataSource={this.state.stations}
            pagination={{
              ...pagination,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
            loading={this.state.loading}
            onChange={(pagination) => this.goToPage(pagination)}
          />
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth.auth,
});

export default connect(mapStateToProps)(Stations);
