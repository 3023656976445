import React, { Component } from "react";
import "./index.scss";
import { Table } from "antd";
import { connect } from "react-redux";
import MainHeadingBar from "../Partials/MainHeadingBar";
import axios from "axios";

class Settings extends Component {
  state = {
    email: "",
    password: "",
    loading: false,
  };

  componentDidMount() {
    axios({
      url: "/user/get_bonus_credentials",
      method: "post",
    })
      .then((res) => {
        this.setState({ loading: false });
        return res;
      })
      .then((res) => {
        if (res.data.success === 1) {
          this.setState({
            email: res.data.creds.email,
            password: res.data.creds.password,
          });
        }
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
  }

  render() {
    let data = [];
    if (this.props.auth.plan.plan_features.BIZFINDER === 1) {
      data = [
        {
          app: "BizFinder",
          url: "https://app.bizfinder.club/",
        },
      ];
    }
    if (this.props.auth.plan.plan_features["3IN1TOOL"] === 1) {
      data = [
        {
          app: "BizFinder",
          url: "https://app.bizfinder.club/",
        },
        {
          app: "MailPush",
          url: "https://app.mailpush.club/",
        },
        {
          app: "TextPush",
          url: "https://app.textpush.club/",
        },
      ];
    }

    return (
      <div className="settings">
        <MainHeadingBar
          title="Add-On Access Credentials"
          {...this.props}
          cta={<span />}
        />
        <Table
          style={{ background: "white" }}
          columns={[
            {
              title: "Bonus",
              dataIndex: "app",
            },
            {
              title: "Access URL",
              dataIndex: "url",
              render: (url) => (
                <a href={url} target="_blank" rel="noopener noreferrer">
                  {url}
                </a>
              ),
            },
            {
              title: "Email",
              render: () => this.state.email,
            },
            {
              title: "Password",
              render: () => this.state.password,
            },
          ]}
          dataSource={data}
          pagination={false}
          loading={this.state.loading}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};

export default connect(mapStateToProps)(Settings);
