import React, { Component } from "react";
import MainHeadingBar from "../Partials/MainHeadingBar";
import {
  Row,
  Skeleton,
  Alert,
  Button,
  Dropdown,
  Icon,
  Menu,
  Card,
  Table,
  Modal,
  message,
} from "antd";
import { connect } from "react-redux";
import "./index.scss";
import { formatNumber } from "../../../helpers/numbers";
import { CDN_URL } from "../../../config";
import Axios from "axios";
import { Redirect } from "react-router-dom";
const { confirm } = Modal;

function AddonsModal({ visible, data, toggleAddonsModal }) {
  const columns = [
    {
      title: "Addon Details",
      dataIndex: "name",
      render: (_, row) => {
        return (
          <div className="product-details">
            <div className="product-info">
              <h4>{row.name}</h4>
              <p>{row.code}</p>
            </div>
          </div>
        );
      },
    },
    {
      title: "Addon Cost",
      dataIndex: "cost",
      render: (cost, row) => (
        <p className="product-cost">
          {row.currency} {formatNumber(cost)}
        </p>
      ),
    },
  ];
  return (
    <Modal
      title="Addons"
      className="product-addons-modal"
      visible={visible}
      footer={null}
      closable={false}
    >
      <Table
        columns={columns || []}
        rowKey={(i) => i.id}
        dataSource={data}
        pagination={false}
      />
      <Button onClick={toggleAddonsModal}>Close</Button>
    </Modal>
  );
}
class Catalog extends Component {
  state = {
    loading: false,
    error: false,
    errorMsg: "",
    catalog: [],
    paging: {
      page: 1,
      total: 0,
      pageSize: 10,
      order: -1,
    },
    sbdom: "",
    qrcode: "",
    wlbldom: null,
    addonsModal: {
      visible: false,
      data: null,
    },
  };

  fetchCatalog() {
    this.setState(
      {
        loading: true,
      },
      () => {
        Axios({
          url: "/zerotouch/get_catalog",
          method: "post",
          data: {
            page: this.state.paging.page,
            pageSize: this.state.paging.pageSize,
            order: this.state.paging.order,
            domid: this.props.match.params.domid,
          },
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                loading: false,
                catalog: res.data.data,
                paging: {
                  ...this.state.paging,
                  total: res.data.total,
                },
                sbdom: res.data.sbdom,
                qrcode: res.data.qrcode,
                wlbldom: res.data.wlbldom,
              });
            } else {
              this.setState({
                loading: false,
                error: true,
                errorMsg: res.data.msg,
              });
            }
          })
          .catch((err) => {
            this.setState({
              loading: false,
              error: true,
              errorMsg: err.message,
            });
          });
      }
    );
  }

  componentDidMount = () => {
    this.fetchCatalog();
  };

  goToPage(pagination) {
    this.setState(
      {
        paging: {
          ...this.state.paging,
          page: pagination.current,
        },
      },
      () => this.fetchCatalog()
    );
  }

  setSortOrder(order) {
    this.setState(
      {
        paging: {
          ...this.state.paging,
          order: order === "asc" ? 1 : -1,
        },
      },
      () => this.fetchCatalog()
    );
  }

  toggleAddonsModal(data = null) {
    this.setState({
      addonsModal: {
        visible: !this.state.addonsModal.visible,
        data,
      },
    });
  }

  makeDefaultCatalog(id) {
    confirm({
      title: "Make Default?",
      content: "Are you sure you want to make this the default catalog",
      onOk: () => {
        Axios({
          url: "/zerotouch/set_catalog_default",
          method: "post",
          data: {
            id,
            domid: this.props.match.params.domid,
          },
        })
          .then((res) => {
            if (res.data.success === 1) {
              message.success("Updated Successfully!");
              this.fetchCatalog();
            } else {
              message.error("There was an error updating the catalog!");
            }
          })
          .catch((err) => {
            message.error("There was an error updating the catalog!");
            console.log(err);
          });
      },
    });
  }

  render() {
    const { loading, error, errorMsg } = this.state;

    if (this.props.auth.domain.role === "store_user") {
      return <Redirect to={`/${this.props.auth.domain.id}/orders`} />;
    }

    let baseURL = this.state.wlbldom
      ? `https://${this.state.wlbldom}`
      : `https://${this.state.sbdom}.zerotouch.agency`;

    const sortMenu = (
      <Menu>
        <Menu.Item key={0}>
          <a href="#/" onClick={() => this.setSortOrder("asc")}>
            Asc. Order
          </a>
        </Menu.Item>
        <Menu.Item key={1}>
          <a href="#/" onClick={() => this.setSortOrder("desc")}>
            Desc. Order
          </a>
        </Menu.Item>
      </Menu>
    );

    const PageWrapper = (children) => (
      <div className="product-container">
        <MainHeadingBar
          title="Catalog Listing"
          pageCTA={
            <span className="sort">
              Sort By Date:{" "}
              <Dropdown overlay={sortMenu}>
                <Button size="default">
                  {this.state.paging.order === 1 ? "Asc." : "Desc."} Order{" "}
                  <Icon type="down" />
                </Button>
              </Dropdown>
            </span>
          }
          cta={
            <>
              <div className="actions">
                <Button
                  size="large"
                  onClick={() =>
                    this.props.history.push(
                      `/${this.props.match.params.domid}/catalog/create`
                    )
                  }
                >
                  Create Catalog
                </Button>
              </div>
            </>
          }
          {...this.props}
        />
        {children}
      </div>
    );

    if (loading) {
      return PageWrapper(
        <Card style={{ marginBottom: "30px" }}>
          <Skeleton active />
        </Card>
      );
    }

    if (!loading && error) {
      return PageWrapper(
        <div>
          <Row>
            <Alert
              message="There was an error"
              description={errorMsg}
              type="error"
            />
          </Row>
        </div>
      );
    }
    if (!loading && !error && !this.state.catalog.length > 0) {
      return PageWrapper(
        <div className="campaign-empty-state">
          <h4>Here for the first time?</h4>
          <p>Looks like you have not created any catalog yet. </p>
          <p>No worries! We've got you covered! </p>
          {this.props.auth.domain.role !== "analyst" && (
            <>
              <p style={{ fontSize: "18px", marginTop: "15px" }}>Or</p>
              <Button
                className="red-button"
                onClick={() =>
                  this.props.history.push(
                    `/${this.props.match.params.domid}/catalog/create`
                  )
                }
              >
                Create Catalog
              </Button>
            </>
          )}
        </div>
      );
    }
    const columns = [
      {
        title: "Catalog Details",
        dataIndex: "image",
        width: "400px",
        render: (_, row) => {
          return (
            <div className="product-details">
              <img src={`${CDN_URL}images/${row.logo}`} alt="not found" />
              <div className="product-info">
                <h4>
                  {row.name}{" "}
                  <a
                    href={`${baseURL}/${row.slug}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <svg
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      style={{ width: 20, position: "relative", top: 3 }}
                    >
                      <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                      <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                    </svg>
                  </a>
                </h4>
                <p>{row.internal_name}</p>
                <p>
                  Theme Main Color{" "}
                  <span
                    style={{
                      width: 16,
                      height: 16,
                      background: row.maincolor,
                      display: "inline-block",
                      verticalAlign: "middle",
                      position: "relative",
                      top: -1,
                    }}
                  />
                </p>
              </div>
            </div>
          );
        },
      },
      {
        title: "Categories",
        width: "400px",
        dataIndex: "categories",
        render: (_, row) => (
          <div style={{ display: "flex" }}>
            <div className="product-category" style={{ flex: 1 }}>
              {row.categories.length === 0 ? (
                <p>No Category Assigned</p>
              ) : (
                row.categories.map((c, i) => (
                  <span className="category-tag" key={`${i}_${c}`}>
                    {c}
                  </span>
                ))
              )}
            </div>
            <div>
              {this.props.auth.domain.role !== "analyst" && (
                <>
                  {row.default_catalog !== 1 ? (
                    <Button
                      style={{
                        background: "#37c627",
                        borderColor: "#37c627",
                        color: "white",
                        marginRight: 15,
                      }}
                      onClick={() => this.makeDefaultCatalog(row._id["$id"])}
                    >
                      Make Default
                    </Button>
                  ) : (
                    <span className="category-tag">Default Catalog</span>
                  )}
                  <Button
                    style={{
                      background: "#37c627",
                      borderColor: "#37c627",
                      color: "white",
                    }}
                    onClick={() =>
                      this.props.history.push(
                        `/${this.props.match.params.domid}/catalog/edit/${
                          row._id["$id"]
                        }`
                      )
                    }
                  >
                    Edit Catalog
                  </Button>
                </>
              )}
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`${baseURL}/${row.slug}`}
                      >
                        Catalog Link
                      </a>
                    </Menu.Item>
                    {this.props.auth.plan.plan_features.CUSTOM_QRCODES ===
                      1 && (
                      <Menu.Item>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`/qrgenerator/get_qr_code?url=${baseURL}/${
                            row.slug
                          }`}
                        >
                          QR Code
                        </a>
                      </Menu.Item>
                    )}
                    {this.props.auth.plan.plan_features.CUSTOM_QRCODES === 1 &&
                      this.state.qrcode && (
                        <Menu.Item>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`/qrgenerator/get_qr_code?foreColor=${this.state.qrcode.fg.substring(
                              1
                            )}&backColor=${this.state.qrcode.bg.substring(
                              1
                            )}&logoname=${
                              this.state.qrcode.logo
                            }&url=${baseURL}/${row.slug}`}
                          >
                            Custom QR Code
                          </a>
                        </Menu.Item>
                      )}
                  </Menu>
                }
              >
                <a
                  className="ant-dropdown-link"
                  href="#!"
                  onClick={(e) => e.preventDefault()}
                  style={{ marginLeft: 15 }}
                >
                  More <Icon type="down" />
                </a>
              </Dropdown>
            </div>
          </div>
        ),
      },
    ];

    const pagination = {
      current: this.state.paging.page,
      pageSize: this.state.paging.pageSize,
      total: this.state.paging.total,
    };

    return PageWrapper(
      <div className="product-list">
        <Row gutter={30}>
          <Table
            columns={columns}
            rowKey={(_, i) => i}
            dataSource={this.state.catalog}
            pagination={{
              ...pagination,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
            loading={this.state.loading}
            onChange={(pagination) => this.goToPage(pagination)}
          />
        </Row>
        <AddonsModal
          {...this.state.addonsModal}
          toggleAddonsModal={() => this.toggleAddonsModal()}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth.auth,
});

export default connect(mapStateToProps)(Catalog);
