import React, { Component } from 'react';
import { signOut } from '../../../store/actions/authActions';
import { connect } from 'react-redux';

class SwitchToAccount extends Component {
  componentDidMount() {
    this.props.signOutRequest();
  }
  componentDidUpdate() {
    this.props.history.push(`/login`)
  }
  render() {
    return (
      <p></p>
    );
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOutRequest: (domid) => dispatch(signOut())
  }
}
const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    auth: state.auth.auth
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SwitchToAccount);