import React, { Component } from "react";
import { Modal, Input, message, Button, Select, DatePicker } from "antd";
import moment, { isMoment } from "moment";
import "./index.scss";
import axios from "axios";
const { Option } = Select;
const { RangePicker } = DatePicker;

class CreateCategoryModal extends Component {
  state = {
    loading: false,
    coupon: {
      name: "",
      code: "",
      amount: {
        type: "fixed",
        value: 0,
      },
      usage: "once",
      user: {
        type: "all",
        email: "",
      },
      enabled: {
        type: "immediately",
        date: { start: "", end: "" },
      },
      catalogs: [],
    },
    catalogs: [],
    errors: {},
  };

  componentDidUpdate(pp) {
    if (pp.visible !== this.props.visible) {
      this.setState({
        coupon: {
          name: "",
          code: "",
          amount: {
            type: "fixed",
            value: 0,
          },
          usage: "once",
          user: {
            type: "all",
            email: "",
          },
          enabled: {
            type: "immediately",
            date: { start: "", end: "" },
          },
          catalogs: [],
        },
      });
    }
    if (
      this.props.visible &&
      this.props.data &&
      JSON.stringify(this.props.data) !== JSON.stringify(pp.data)
    ) {
      this.setState({
        coupon: this.props.data,
      });
    }
  }

  fetchCatalog() {
    axios({
      url: "/zerotouch/get_catalog",
      method: "post",
      data: {
        page: 1,
        pageSize: 100000,
        domid: this.props.match.params.domid,
      },
    })
      .then((res) => {
        if (res.data.success === 1) {
          this.setState({
            catalogs: res.data.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  onChange = (k, v) => {
    this.setState((state) => ({
      coupon: {
        ...state.coupon,
        [k]: v,
      },
    }));
  };

  validate = () => {
    const coupon = this.state.coupon;
    const errors = {};
    if (!coupon.name) {
      errors.name = "This is a required field!";
    }
    if (!coupon.code) {
      errors.code = "This is a required field!";
    }
    if (!coupon.amount || !coupon.amount.value) {
      errors.value = "This is a required field!";
    }
    if (coupon.user.type === "email" && !coupon.user.email) {
      errors.user = "This is a required field!";
    }
    if (
      coupon.enabled.type === "date" &&
      (!coupon.enabled.start || !coupon.enabled.end)
    ) {
      errors.daterange = "This is a required field!";
    }
    if (!coupon.catalogs.length) {
      errors.catalogs = "This is a required field!";
    }

    this.setState({ errors });
    if (Object.keys(errors).length > 0) {
      return false;
    }
    return true;
  };

  onSubmit = () => {
    if (this.validate()) {
      const coupon = {
        name: this.state.coupon.name,
        code: this.state.coupon.code,
        amount: this.state.coupon.amount,
        usage: this.state.coupon.usage,
        user: this.state.coupon.user,
        enabled: this.state.coupon.enabled,
        catalogs: this.state.coupon.catalogs,
      };
      if (this.state.coupon._id) {
        coupon.edit = this.state.coupon._id["$id"];
      }
      this.setState(
        {
          loading: true,
        },
        () => {
          axios({
            url: "/zerotouch/add_coupon",
            method: "post",
            data: {
              coupon,
              domid: this.props.match.params.domid,
            },
          })
            .then((res) => {
              this.setState({
                loading: false,
              });
              return res;
            })
            .then((res) => {
              if (res.data.success === 1) {
                this.props.toggleCouponModal();
                this.props.reloadList();
              } else {
                message.error(res.data.msg);
              }
            })
            .catch((err) => {
              message.error("There was an error!");
              console.log(err);
            });
        }
      );
    }
  };

  componentDidMount() {
    this.fetchCatalog();
  }

  render() {
    const errors = this.state.errors;
    return (
      <Modal
        title="Create Coupon"
        visible={this.props.visible}
        className="categoryModal"
        onCancel={this.props.toggleCouponModal}
        closable={false}
        maskClosable={false}
        footer={[
          <Button
            key="save"
            onClick={() => this.onSubmit()}
            style={{ border: "none", background: "#37C627", color: "white" }}
          >
            Save Coupon
          </Button>,
          <Button
            key="cancel"
            onClick={() => this.props.toggleCouponModal()}
            style={{ border: "1px solid #707070", color: "#707070" }}
          >
            Cancel
          </Button>,
        ]}
      >
        <div>
          <div className={`form-item-group ${errors.name ? "has-error" : ""}`}>
            <label className="ant-form-item-label">
              Coupon Name (Internal Reference Only):
            </label>
            <Input
              placeholder="Enter coupon name"
              value={this.state.coupon.name}
              onChange={(e) => this.onChange("name", e.target.value)}
            />
            {errors.name && <p className="error-label">{errors.name}</p>}
          </div>
          <div className={`form-item-group ${errors.code ? "has-error" : ""}`}>
            <label className="ant-form-item-label">Coupon Code:</label>
            <Input
              placeholder="Enter coupon code"
              value={this.state.coupon.code}
              onChange={(e) => this.onChange("code", e.target.value)}
            />
            {errors.code && <p className="error-label">{errors.code}</p>}
          </div>
          <div className={`form-item-group ${errors.value ? "has-error" : ""}`}>
            <label className="ant-form-item-label">Coupon Value:</label>
            <Input
              placeholder="Enter coupon value"
              value={this.state.coupon.amount.value}
              onChange={(e) =>
                this.onChange("amount", {
                  ...this.state.coupon.amount,
                  value: e.target.value,
                })
              }
              addonBefore={
                <Select
                  placeholder="Select"
                  value={this.state.coupon.amount.type}
                  style={{ width: "125px" }}
                  onChange={(val) =>
                    this.onChange("amount", {
                      ...this.state.coupon.amount,
                      type: val,
                    })
                  }
                >
                  <Option value="fixed">Fixed</Option>
                  <Option value="percentage">Percentage</Option>
                </Select>
              }
            />
            {errors.value && <p className="error-label">{errors.value}</p>}
          </div>
          <div className={`form-item-group ${errors.usage ? "has-error" : ""}`}>
            <label className="ant-form-item-label">Coupon Usage:</label>
            <Select
              placeholder="Select"
              value={this.state.coupon.usage}
              style={{ width: "100%" }}
              onChange={(val) => this.onChange("usage", val)}
            >
              <Option value="once">Once</Option>
              <Option value="unlimited">Unlimited</Option>
            </Select>
            {errors.usage && <p className="error-label">{errors.usage}</p>}
          </div>
          <div className={`form-item-group`}>
            <label className="ant-form-item-label">Coupon User:</label>
            <Select
              placeholder="Select"
              value={this.state.coupon.user.type}
              style={{ width: "100%" }}
              onChange={(val) =>
                this.onChange("user", { ...this.state.coupon.user, type: val })
              }
            >
              <Option value="all">All</Option>
              <Option value="logged_in">Logged In Users</Option>
              <Option value="email">Specific Email</Option>
            </Select>
          </div>
          {this.state.coupon.user.type === "email" ? (
            <div
              className={`form-item-group ${errors.user ? "has-error" : ""}`}
            >
              <label className="ant-form-item-label">User Email:</label>
              <Input
                placeholder="Enter user email"
                value={this.state.coupon.user.email}
                onChange={(e) =>
                  this.onChange("user", {
                    ...this.state.coupon.user,
                    email: e.target.value,
                  })
                }
              />
              {errors.user && <p className="error-label">{errors.user}</p>}
            </div>
          ) : null}

          <div className={`form-item-group`}>
            <label className="ant-form-item-label">Coupon Enabled:</label>
            <Select
              placeholder="Select"
              value={this.state.coupon.enabled.type}
              style={{ width: "100%" }}
              onChange={(val) =>
                this.onChange("enabled", {
                  ...this.state.coupon.enabled,
                  type: val,
                })
              }
            >
              <Option value="immediately">Immediately</Option>
              <Option value="date">Date Range</Option>
            </Select>
          </div>
          {this.state.coupon.enabled.type === "date" ? (
            <div
              className={`form-item-group ${
                errors.daterange ? "has-error" : ""
              }`}
            >
              <label className="ant-form-item-label">Date Range:</label>
              <RangePicker
                style={{ marginLeft: "10px" }}
                value={[
                  isMoment(moment(this.state.coupon.enabled.start))
                    ? moment(this.state.coupon.enabled.start)
                    : undefined,
                  isMoment(moment(this.state.coupon.enabled.end))
                    ? moment(this.state.coupon.enabled.end)
                    : undefined,
                ]}
                onChange={(dates) =>
                  this.onChange("enabled", {
                    ...this.state.coupon.enabled,
                    start: dates[0] ? dates[0].format("YYYY-MM-DD") : "",
                    end: dates[1] ? dates[1].format("YYYY-MM-DD") : "",
                  })
                }
              />
              {errors.daterange && (
                <p className="error-label">{errors.daterange}</p>
              )}
            </div>
          ) : null}

          <div
            className={`form-item-group ${errors.catalogs ? "has-error" : ""}`}
          >
            <label className="ant-form-item-label">Select Catalogs:</label>
            <Select
              placeholder="Select catalogs"
              value={this.state.coupon.catalogs}
              style={{ width: "100%" }}
              mode="multiple"
              onChange={(val) => this.onChange("catalogs", val)}
            >
              {this.state.catalogs.map((c) => (
                <Option key={c._id["$id"]} value={c._id["$id"]}>
                  {c.name}
                </Option>
              ))}
            </Select>
            {errors.catalogs && (
              <p className="error-label">{errors.catalogs}</p>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

export default CreateCategoryModal;
