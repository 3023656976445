import React, { Component } from "react";
import "./index.scss";
import { Row, Col, Form, Icon, Input, Button, Checkbox, Alert } from "antd";
import { Link } from "react-router-dom";
import FormContainer from "../FormContainer";
import { connect } from "react-redux";
import { signIn } from "../../../store/actions/authActions";
import { Redirect } from "react-router-dom";

class Login extends Component {
  state = {
    email: "",
    password: "",
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err) => {
      if (!err) {
        this.props.signIn(this.state);
      }
    });
  };

  componentDidMount() {
    document.title = this.props.title;
  }
  componentWillReceiveProps(props) {
    document.title = props.title;
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { authError, auth } = this.props;

    if (auth.logged_in) {
      let domid = "";
      if (auth.domain && auth.domain.id) {
        domid = auth.domain.id;
      }
      return <Redirect to={`/switch-account/${domid}`} />;
    }

    return (
      <Row className="login-form">
        <Col xs={15} className="sidebar" />
        <Col xs={9} style={{ height: "100%" }}>
          <FormContainer>
            <h5 className="heading">Welcome Back.</h5>
            <p className="subheading">Log In to access your dashboard</p>

            <Form onSubmit={this.handleSubmit} className="login-form">
              <Form.Item>
                {getFieldDecorator("email", {
                  rules: [
                    {
                      type: "email",
                      required: true,
                      message: "Please input your email!",
                    },
                  ],
                })(
                  <Input
                    name="email"
                    prefix={
                      <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    placeholder="Your Email Id"
                    onChange={this.handleChange}
                  />
                )}
              </Form.Item>

              <Form.Item>
                {getFieldDecorator("password", {
                  rules: [
                    { required: true, message: "Please input your Password!" },
                  ],
                })(
                  <Input
                    name="password"
                    prefix={
                      <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    type="password"
                    placeholder="Your Password"
                    onChange={this.handleChange}
                  />
                )}
              </Form.Item>

              {authError && (
                <div className="ant-form-item">
                  <Alert
                    message={authError}
                    type="error"
                    style={{ width: "100%" }}
                  />
                </div>
              )}

              <Form.Item className="flex-div">
                {getFieldDecorator("remember", {
                  valuePropName: "checked",
                  initialValue: true,
                })(<Checkbox className>Remember me</Checkbox>)}

                <span className="gap" />

                <Link className="login-form-forgot" to="/forgot-password">
                  Forgot password
                </Link>
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="form-button"
                >
                  Sign In
                </Button>
              </Form.Item>

              {/* <Form.Item className="flex-div">
                                Don’t have an account?

                                <span className="gap"></span>

                                <Link to="/signup">Sign Up Here</Link>
                            </Form.Item> */}
            </Form>
          </FormContainer>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    auth: state.auth.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (creds) => dispatch(signIn(creds)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(Login));
