import React, { Component } from "react";
import MainHeadingBar from "../../Partials/MainHeadingBar";
import LibraryModal from "../../Library/LibraryModal";
import {
  Card,
  Input,
  Button,
  Icon,
  Select,
  Modal,
  message,
  Skeleton,
} from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { CDN_URL } from "../../../../config";
import "./index.scss";
import axios from "axios";
const { Option } = Select;
const { confirm } = Modal;

class CreateProduct extends Component {
  state = {
    loading: false,
    submitting: false,
    prefetching: false,
    product: {
      name: "",
      description: "",
      type: "product",
      foodtype: "vegetarian",
      cost: "",
      compareatprice: "",
      currency: "USD",
      featured_image: "",
      categories: [],
      addons: [],
    },
    categories: [],
    currencies: [],
    libraryModal: {
      visible: false,
      tab: "upload",
    },
    errors: {},
  };

  fetchCategories() {
    axios({
      url: "/zerotouch/get_categories",
      method: "post",
      data: {
        page: 1,
        pageSize: 100000,
        domid: this.props.match.params.domid,
      },
    })
      .then((res) => {
        if (res.data.success === 1) {
          this.setState({
            categories: res.data.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  fetchCurrencies() {
    axios({
      url: "/zerotouch/get_currencies",
      method: "post",
      data: {
        domid: this.props.match.params.domid,
      },
    })
      .then((res) => {
        const currencies = [];
        Object.entries(res.data).forEach((e) =>
          currencies.push({ code: e[0], symbol: e[1] })
        );
        this.setState({
          currencies,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  fetchProductData() {
    this.setState(
      {
        prefetching: true,
      },
      () => {
        axios({
          url: "/zerotouch/get_product",
          method: "post",
          data: {
            id: this.props.match.params.id,
            domid: this.props.match.params.domid,
          },
        })
          .then((res) => {
            this.setState({
              prefetching: false,
            });
            return res;
          })
          .then((res) => {
            if (res.data.success === 1) {
              const upd = {
                ...res.data.product,
              };
              if (this.props.edit) {
                upd["edit"] = this.props.match.params.id;
              }
              this.setState({
                product: upd,
              });
            } else {
              this.props.history.push("/404");
            }
          })
          .catch((err) => {
            this.props.history.push("/404");
          });
      }
    );
  }

  componentDidMount() {
    this.fetchCategories();
    this.fetchCurrencies();
    if (this.props.edit || this.props.clone) {
      this.fetchProductData();
    }
  }

  addAddon() {
    this.setState((state) => ({
      product: {
        ...state.product,
        addons: [
          ...state.product.addons,
          {
            id: Date.now(),
            name: "",
            code: "",
            cost: "",
            currency: "USD",
          },
        ],
      },
    }));
  }

  removeAddon(id) {
    confirm({
      title: "Are you sure?",
      content: "Are you sure you want to remove this add-on?",
      onOk: () => {
        this.setState((state) => ({
          product: {
            ...state.product,
            addons: state.product.addons.filter((a) => a.id !== id),
          },
        }));
      },
    });
  }

  updateAddon(id, k, v) {
    this.setState((state) => ({
      product: {
        ...state.product,
        addons: state.product.addons.map((a) => {
          if (a.id === id) {
            return { ...a, [k]: v };
          } else {
            return a;
          }
        }),
      },
    }));
  }

  validate = () => {
    const product = this.state.product;
    const errors = {};
    if (!product.name) {
      errors.name = "This is a required field!";
    }
    if (!product.description) {
      errors.description = "This is a required field!";
    }
    if (
      !product.cost ||
      !product.cost.match(/[\d.]/) ||
      (product.cost.match(/\./g) && product.cost.match(/\./g).length > 1)
    ) {
      errors.cost = "Please enter a valid value!";
    }
    if (!product.currency) {
      errors.cost = "This is a required field!";
    }
    if (
      product.compareatprice &&
      (!product.compareatprice.match(/[\d.]/) ||
        (product.compareatprice.match(/\./g) &&
          product.compareatprice.match(/\./g).length > 1))
    ) {
      errors.compareatprice = "Please enter a valid value!";
    }
    if (!product.featured_image) {
      errors.featured_image = "This is a required field!";
    }
    if (product.addons.length) {
      product.addons.forEach((addon) => {
        if (!addon.name) {
          errors[`addon_${addon.id}_name`] = "This is a required field!";
        }
        if (
          !addon.cost ||
          !addon.cost.match(/[\d.]/) ||
          (addon.cost.match(/\./g) && addon.cost.match(/\./g).length > 1)
        ) {
          errors[`addon_${addon.id}_cost`] = "Please enter a valid value!";
        }
        if (!addon.currency) {
          errors[`addon_${addon.id}_cost`] = "Please enter a valid value!";
        }
        if (
          addon.compareatprice &&
          (!addon.compareatprice.match(/[\d.]/) ||
            (addon.compareatprice.match(/\./g) &&
              addon.compareatprice.match(/\./g).length > 1))
        ) {
          errors[`addon_${addon.id}_compareatprice`] =
            "Please enter a valid value!";
        }
      });
    }

    this.setState({ errors });
    if (Object.keys(errors).length > 0) {
      return false;
    }
    return true;
  };

  onSubmit = () => {
    if (this.validate()) {
      this.setState(
        {
          submitting: true,
        },
        () => {
          axios({
            url: "/zerotouch/add_product",
            method: "post",
            data: {
              product: this.state.product,
              domid: this.props.match.params.domid,
            },
          })
            .then((res) => {
              this.setState({
                submitting: false,
              });
              return res;
            })
            .then((res) => {
              if (res.data.success === 1) {
                this.props.history.push(
                  `/${this.props.match.params.domid}/products`
                );
              } else {
                message.error(res.data.msg);
              }
            })
            .catch((err) => {
              message.error("There was an error!");
              console.log(err);
            });
        }
      );
    }
  };

  onChange = (k, v) => {
    this.setState((state) => ({
      product: {
        ...state.product,
        [k]: v,
      },
    }));
  };

  toggleLibraryModal(tab = "upload") {
    this.setState((state) => ({
      libraryModal: {
        ...state.libraryModal,
        tab,
        visible: !state.libraryModal.visible,
      },
    }));
  }

  onChoose(data) {
    this.onChange("featured_image", data.media_filename);
    this.toggleLibraryModal();
  }

  render() {
    const errors = this.state.errors;

    if (this.state.prefetching) {
      return <Skeleton active />;
    }
    return (
      <div>
        <MainHeadingBar
          title={`${this.props.edit ? "Update" : "Create New"} Product`}
          {...this.props}
          cta={<div />}
        />
        <Card className="product-card">
          <h4>
            Let's {this.props.edit ? "Update Your" : "Create A New"} Product!
          </h4>

          <div className={`form-item-group ${errors.name ? "has-error" : ""}`}>
            <label className="ant-form-item-label">Product Name:</label>
            <Input
              placeholder="Enter the name of the product"
              value={this.state.product.name}
              onChange={(e) => this.onChange("name", e.target.value)}
            />
            {errors.name && <p className="error-label">{errors.name}</p>}
          </div>
          <div className={`form-item-group`}>
            <label className="ant-form-item-label">Product Type:</label>
            <Select
              style={{ width: "100%" }}
              placeholder="Select Product Type"
              value={this.state.product.type}
              onChange={(val) => this.onChange("type", val)}
            >
              <Option value="product">Product/Service</Option>
              <Option value="tangible_item">Tangible Item</Option>
              <Option value="food_beverage">Food Item/Beverage</Option>
            </Select>
          </div>
          {this.state.product.type === "food_beverage" && (
            <div className={`form-item-group`}>
              <label className="ant-form-item-label">Food/Beverage Type:</label>
              <Select
                style={{ width: "100%" }}
                placeholder="Select Food Type"
                value={this.state.product.foodtype}
                onChange={(val) => this.onChange("foodtype", val)}
              >
                <Option value="vegetarian">Vegetarian</Option>
                <Option value="non_vegetarian">Non-Vegetarian</Option>
                <Option value="vegan">Vegan</Option>
              </Select>
            </div>
          )}
          <div className={`form-item-group ${errors.cost ? "has-error" : ""}`}>
            <label className="ant-form-item-label">Product Cost:</label>
            <Input
              placeholder="Enter the product cost"
              value={this.state.product.cost}
              onChange={(e) =>
                e.target.value.split(".").length < 3 &&
                this.onChange("cost", e.target.value.replace(/[^\d.]/, ""))
              }
              addonBefore={
                <Select
                  value={this.state.product.currency}
                  onChange={(val) => this.onChange("currency", val)}
                  showSearch
                  filterOption={true}
                  style={{ width: 100 }}
                >
                  {this.state.currencies.map((c) => (
                    <Option key={c.code} value={c.code}>
                      {c.code} ({c.symbol})
                    </Option>
                  ))}
                </Select>
              }
            />
            {errors.cost && <p className="error-label">{errors.cost}</p>}
          </div>
          <div
            className={`form-item-group ${
              errors.compareatprice ? "has-error" : ""
            }`}
          >
            <label className="ant-form-item-label">Compare at Price:</label>
            <Input
              placeholder="Enter the product compared at price"
              value={this.state.product.compareatprice}
              onChange={(e) =>
                e.target.value.split(".").length < 3 &&
                this.onChange(
                  "compareatprice",
                  e.target.value.replace(/[^\d.]/, "")
                )
              }
              addonBefore={this.state.product.currency}
            />
            {errors.compareatprice && (
              <p className="error-label">{errors.compareatprice}</p>
            )}
          </div>
          <div
            className={`form-item-group ${
              errors.description ? "has-error" : ""
            }`}
          >
            <label className="ant-form-item-label">Product Description:</label>
            <ReactQuill
              className="notranslate"
              value={this.state.product.description}
              onChange={(val) => this.onChange("description", val)}
            />
            {errors.description && (
              <p className="error-label">{errors.description}</p>
            )}
          </div>
          <div className={`form-item-group`}>
            <label className="ant-form-item-label">Product Category:</label>
            <Select
              placeholder="Select the product category or enter a new category"
              value={this.state.product.categories}
              style={{ width: "100%" }}
              mode="multiple"
              showSearch
              filterOption={(inputValue, option) =>
                new RegExp(inputValue, "i").test(option.props.children)
              }
              onChange={(val) => this.onChange("categories", val)}
            >
              {this.state.categories.map((c) => (
                <Option key={c._id["$id"]} value={c._id["$id"]}>
                  {c.name}
                </Option>
              ))}
            </Select>
          </div>
          <div className={`form-item-group`}>
            <label className="ant-form-item-label">
              Product Code (Optional):
            </label>
            <Input
              placeholder="Assign a product code for internal reference"
              value={this.state.product.code}
              onChange={(e) => this.onChange("code", e.target.value)}
            />
          </div>
          <div
            className={`form-item-group ${
              errors.featured_image ? "has-error" : ""
            }`}
          >
            <label className="ant-form-item-label">Featured Image:</label>
            <div className="upload-actions">
              <div className="upload-action">
                {this.state.product.featured_image && (
                  <img
                    alt="product featured"
                    src={`${CDN_URL}images/${
                      this.state.product.featured_image
                    }`}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                )}
              </div>
              <div
                className="upload-action"
                onClick={() => this.toggleLibraryModal("upload")}
              >
                <p>
                  <Icon type="upload" style={{ color: "#D9D9D9" }} /> <br />
                  Upload
                </p>
              </div>
              <div
                className="upload-action"
                onClick={() => this.toggleLibraryModal("library")}
              >
                <p>
                  <Icon type="folder-open" style={{ color: "#D9D9D9" }} />{" "}
                  <br />
                  Library
                </p>
              </div>
            </div>
            {errors.featured_image && (
              <p className="error-label">{errors.featured_image}</p>
            )}
          </div>

          <div className="addons">
            {this.state.product.addons.map((addon) => (
              <div className="addon" key={addon.id}>
                <div
                  className={`form-item-group ${
                    errors[`addon_${addon.id}_name`] ? "has-error" : ""
                  }`}
                >
                  <label className="ant-form-item-label">Add-On Name:</label>
                  <Input
                    placeholder="Enter add-on name"
                    value={addon.name}
                    onChange={(e) =>
                      this.updateAddon(addon.id, "name", e.target.value)
                    }
                  />
                  {errors[`addon_${addon.id}_name`] && (
                    <p className="error-label">
                      {errors[`addon_${addon.id}_name`]}
                    </p>
                  )}
                </div>
                <div className={`form-item-group`}>
                  <label className="ant-form-item-label">Add-On Code:</label>
                  <Input
                    placeholder="Assign add-on code"
                    value={addon.code}
                    onChange={(e) =>
                      this.updateAddon(addon.id, "code", e.target.value)
                    }
                  />
                </div>
                <div
                  className={`form-item-group ${
                    errors[`addon_${addon.id}_cost`] ? "has-error" : ""
                  }`}
                >
                  <label className="ant-form-item-label">Add-On Cost:</label>
                  <Input
                    placeholder="Enter the add-on cost"
                    value={addon.cost}
                    style={{
                      width: "100%",
                      display: "inline-block",
                    }}
                    onChange={(e) =>
                      e.target.value.split(".").length < 3 &&
                      this.updateAddon(
                        addon.id,
                        "cost",
                        e.target.value.replace(/[^\d.]/, "")
                      )
                    }
                    addonBefore={
                      <Select
                        value={addon.currency}
                        showSearch
                        filterOption={true}
                        onChange={(val) =>
                          this.updateAddon(addon.id, "currency", val)
                        }
                        style={{ width: 130 }}
                      >
                        {this.state.currencies.map((c) => (
                          <Option key={c.code} value={c.code}>
                            {c.code} ({c.symbol})
                          </Option>
                        ))}
                      </Select>
                    }
                  />
                </div>
                <div
                  className={`form-item-group ${
                    errors[`addon_${addon.id}_compareatprice`]
                      ? "has-error"
                      : ""
                  }`}
                >
                  <label className="ant-form-item-label">Add-On Cost:</label>
                  <Input
                    placeholder="Enter the Addon Compare At Price"
                    value={addon.compareatprice}
                    style={{
                      width: "calc(100% - 50px)",
                      display: "inline-block",
                    }}
                    onChange={(e) =>
                      e.target.value.split(".").length < 3 &&
                      this.updateAddon(
                        addon.id,
                        "compareatprice",
                        e.target.value.replace(/[^\d.]/, "")
                      )
                    }
                    addonBefore={addon.currency}
                  />
                  <a href="#!" onClick={() => this.removeAddon(addon.id)}>
                    <Icon type="close-circle" style={{ fontSize: 25 }} />
                  </a>
                  {errors[`addon_${addon.id}_compareatprice`] && (
                    <p className="error-label">
                      {errors[`addon_${addon.id}_compareatprice`]}
                    </p>
                  )}
                </div>
              </div>
            ))}
          </div>

          <div className={`form-item-group ${errors.name ? "has-error" : ""}`}>
            <a
              href="#!"
              className="add-addon-link"
              onClick={() => this.addAddon()}
            >
              + Create Add-On(s)
            </a>
          </div>

          <div>
            <Button
              className="footer-button"
              onClick={() => this.onSubmit()}
              loading={this.state.submitting}
            >
              {this.props.edit ? "" : "Create &"} Save Product
            </Button>
          </div>
        </Card>
        <LibraryModal
          {...this.props}
          onCancel={() => this.toggleLibraryModal()}
          visible={this.state.libraryModal.visible}
          tab={this.state.libraryModal.tab}
          onChoose={(data) => this.onChoose(data)}
        />
      </div>
    );
  }
}
export default CreateProduct;
