import { Button, Input, message, Modal } from "antd";
import axios from "axios";
import React, { Component } from "react";
import FormattingField from "../../../Partials/FormattingField";
import "./index.scss";

const englishTranslations = {
  add: "Add",
  no_products_selected: "No Products Selected",
  no_products_selected_text:
    "You haven't selected any products yet. Please select the products to see their final cost in your cart",
  back_to_homepage: "Go back to homepage",
  search: "Search",
  welcome_back: "Welcome Back!",
  email: "Email",
  password: "Password",
  login: "Login",
  no_account: "Don't have an account?",
  register: "Register",
  product_name: "Product name!",
  quantity: "Qty",
  price: "Price",
  total_cost: "Total cost",
  your_orders: "Your Orders",
  add_ons: "Add-Ons",
  continue_add_ons: "Continue with Selected Add-ons",
  remove: "Remove",
  confirm_order: "Confirm Order",
  continue_to_pay: "Continue to Pay",
  cash: "Cash",
  pay_confirm_order: "Pay & Confirm order",
  select_payment_gateway: "Select Payment Gateway",
  order_status: "Order Status",
  order_being_prepared: "Order is being Prepared...",
  download_invoice: "Download invoice",
  logout: "Logout",
  otp_line:
    "An OTP has been sent to your registered mobile number. Please enter the OTP received below",
  enter_otp: "Enter OTP",

  order_details: "Order Details",
  order_id: "Order ID",
  amount: "Amount",
  date: "Date",
  pending: "Pending",
  ready: "Ready",
  cancelled: "Cancelled",
  delivered: "Delivered",
  order_ready: "Order Is Ready!",
  share_feedback: "Share Feedback",
  leave_a_feedback: "Leave A Feedback",
  feedback_modal_subline: "We would love if you could leave us a feedback:",
  share_my_feedback: "Save My Feedback",
  continue_as_guest: "Continue as Guest",
  create_account: "Create an Account",
  name: "Name",
  phone: "Phone",
  already_have_an_account: "Already have an account?",
  required_field: "This is a required field!",
  addons_for_item: "Addons for Item [item_num]",

  power_social_distance:
    "Now order with the power of social distancing in your phone.",
  order_from_seat: "To order from the comfort of your seat",
  enter_table_code: "Enter Following Station Code",
  scan_qr_code: "Scan the QR Code",
  visit: "Visit",
  contactless_ordering: "Contactless ordering from your saftey & ours.",
  be_smart: "Be smart and stay at home!",
  scan_order_pay:
    "Scan the QR Code to view catalog on web & Access the online catalog to Order & Pay contactless.",
  trouble_scanning: "Having trouble scanning?",
  table_id: "Station ID",
  order_steps: "You can also Order by the following steps",
  visit_our_website: "Visit our website",
  proceed_with_order: "Proceed with your order",
  scan_order_pay_caps: "SCAN, ORDER & PAY",
  hashtag_contactless: "#CONTACTLESS",
  order_instructions: "Enter Any Order Related Instructions",

  forgot_password: "Forgot your password?",
  reset_password: "Reset Password",
  reset_email_sent:
    "An email containing the password reset link has been sent!",
  password_reset_success:
    "Your password has been successfully reset. You can now login using your new password.",
  taxable_amount: "Taxable Amount",
  total_tax: "Total Tax",

  invoice_to: "Invoice To",
  purchase_invoice: "PURCHASE INVOICE",
  date_of_invoice: "Date of Invoice",
  description: "DESCRIPTION",
  total: "Total",
  thank_you: "Thank You",
  tax_id: "Tax ID",

  tips_headline: "Add tip to support your server",
  tip_amount: "Tip Amount",
  required_tip_error: "Please select a tip amount!",
  no_tip: "No Tip",

  delivery_charge: "Delivery Charge",
  ordertype_dinein: "Dine-In",
  ordertype_takeaway: "Takeaway",
  ordertype_delivery: "Delivery",
  required_order_type: "Please select an order type!",
  enter_delivery_address: "Enter Delivery Address",
  required_delivery_address: "Please enter the delivery address!",
  delivery_not_available: "Delivery Not Available at this time!",
  not_accepting_orders: "Not Accepting Orders",

  coupon_discount: "Discount",
  coupon_code: "Coupon Code",
  coupon_apply: "Apply",
};

const defaultState = {
  loading: false,
  translation: {
    translation_name: "",
    translation_data: {
      add: "",
      no_products_selected: "",
      no_products_selected_text: "",
      back_to_homepage: "",
      search: "",
      welcome_back: "",
      email: "",
      password: "",
      login: "",
      no_account: "",
      register: "",
      product_name: "",
      quantity: "",
      price: "",
      total_cost: "",
      tnc: "",
      add_ons: "",
      continue_add_ons: "",
      remove: "",
      confirm_order: "",
      continue_to_pay: "",
      cash: "",
      pay_confirm_order: "",
      select_payment_gateway: "",
      order_status: "",
      order_being_prepared: "",
      download_invoice: "",
      logout: "",
      otp_line: "",
      enter_otp: "",
      order_details: "",
      order_id: "",
      amount: "",
      date: "",
      pending: "",
      ready: "",
      cancelled: "",
      delivered: "",
      order_ready: "",
      share_feedback: "",
      leave_a_feedback: "",
      feedback_modal_subline: "",
      share_my_feedback: "",
      continue_as_guest: "",
      create_account: "",
      name: "",
      phone: "",
      already_have_an_account: "",
      required_field: "",
      addons_for_item: "",
      power_social_distance: "",
      order_from_seat: "",
      enter_table_code: "",
      scan_qr_code: "",
      visit: "",
      contactless_ordering: "",
      be_smart: "",
      scan_order_pay: "",
      trouble_scanning: "",
      table_id: "",
      order_steps: "",
      visit_our_website: "",
      proceed_with_order: "",
      scan_order_pay_caps: "",
      hashtag_contactless: "",
      order_instructions: "",
      forgot_password: "",
      reset_password: "",
      reset_email_sent: "",
      password_reset_success: "",
      taxable_amount: "",
      total_tax: "",
      invoice_to: "",
      purchase_invoice: "",
      date_of_invoice: "",
      description: "",
      total: "",
      thank_you: "",
      tax_id: "",
      tips_headline: "",
      tip_amount: "",
      required_tip_error: "",
      no_tip: "",
      delivery_charge: "",
      ordertype_dinein: "",
      ordertype_takeaway: "",
      ordertype_delivery: "",
      required_order_type: "",
      enter_delivery_address: "",
      required_delivery_address: "",
      delivery_not_available: "",
      not_accepting_orders: "",
      coupon_discount: "",
      coupon_code: "",
      coupon_apply: "",
    },
  },
  errors: {},
};

class AddTranslationModal extends Component {
  state = { ...defaultState };

  onChange = (k, v) => {
    this.setState((state) => ({
      translation: {
        ...state.translation,
        translation_data: {
          ...state.translation.translation_data,
          [k]: v,
        },
      },
    }));
  };

  onNameChange = (v) => {
    this.setState((state) => ({
      translation: {
        ...state.translation,
        translation_name: v,
      },
    }));
  };

  componentDidUpdate(pp) {
    if (pp.visible !== this.props.visible) {
      this.setState({
        ...defaultState,
      });
    }
    if (
      this.props.visible &&
      this.props.data &&
      JSON.stringify(this.props.data) !== JSON.stringify(pp.data)
    ) {
      this.setState({
        translation: {
          ...this.state.translation,
          ...this.props.data,
        },
      });
    }
  }

  validate = () => {
    const translation = this.state.translation;
    const errors = {};
    if (!translation.translation_name) {
      errors.translation_name = "This is a required field!";
    }

    Object.keys(englishTranslations).forEach((k) => {
      if (!translation.translation_data[k]) {
        errors[k] = "This is a required field!";
      }
    });

    this.setState({ errors });
    if (Object.keys(errors).length > 0) {
      return false;
    }
    return true;
  };

  onSubmit = () => {
    if (this.validate()) {
      this.setState(
        {
          loading: true,
        },
        () => {
          axios({
            url: "/zerotouch/add_translation",
            method: "post",
            data: {
              ...this.state.translation,
              domid: this.props.match.params.domid,
            },
          })
            .then((res) => {
              this.setState({
                loading: false,
              });
              return res;
            })
            .then((res) => {
              if (res.data.success === 1) {
                message.success(
                  `${
                    this.state.translation.edit ? "Updated" : "Added"
                  } Successfully!`
                );
                this.props.toggleTranslationModal();
                this.props.reloadList();
              } else {
                message.error(res.data.msg);
              }
            })
            .catch((err) => {
              message.error("There was an error!");
            });
        }
      );
    }
  };

  render() {
    const errors = this.state.errors;
    return (
      <Modal
        title={`${this.state.translation.edit ? "Edit" : "Add"} Translation`}
        visible={this.props.visible}
        className="translationModal"
        onCancel={this.props.toggleTranslationModal}
        closable={!this.state.loading}
        footer={[
          <Button
            key={0}
            onClick={() => this.onSubmit()}
            style={{ border: "none", background: "#37C627", color: "white" }}
          >
            {this.state.translation.edit ? "Update" : "Add"} Translation
          </Button>,
          <Button
            key={1}
            onClick={() => this.props.toggleTranslationModal()}
            style={{ border: "1px solid #707070", color: "#707070" }}
          >
            Cancel
          </Button>,
        ]}
      >
        <div>
          <div
            className={`form-item-group ${
              errors.translation_name ? "has-error" : ""
            }`}
          >
            <label className="ant-form-item-label">Translation Name:</label>
            <Input
              placeholder="Enter translation language"
              value={this.state.translation.translation_name}
              onChange={(e) => this.onNameChange(e.target.value)}
            />
            {errors.translation_name && (
              <p className="error-label">{errors.translation_name}</p>
            )}
          </div>

          <label>
            <b>Enter translation for the following phrases:</b>
          </label>

          {Object.keys(englishTranslations).map((k) => {
            return (
              <div
                key={k}
                className={`form-item-group ${errors[k] ? "has-error" : ""}`}
              >
                <label className="ant-form-item-label">
                  {englishTranslations[k]}:
                </label>
                {k === "addons_for_item" ? (
                  <FormattingField
                    dropdownLabel="Add Placeholder"
                    label={null}
                    field={(props) => (
                      <Input placeholder={englishTranslations[k]} {...props} />
                    )}
                    personalizations={[
                      { tag: "[item_num]", title: "Item Number" },
                    ]}
                    value={this.state.translation.translation_data[k]}
                    onChange={(val) => this.onChange(k, val)}
                  />
                ) : (
                  <Input
                    placeholder={englishTranslations[k]}
                    value={this.state.translation.translation_data[k]}
                    onChange={(e) => this.onChange(k, e.target.value)}
                  />
                )}
                {errors[k] && <p className="error-label">{errors[k]}</p>}
              </div>
            );
          })}
        </div>
      </Modal>
    );
  }
}

export default AddTranslationModal;
