import React, { Component } from "react";
import "./index.scss";
import { connect } from "react-redux";
import { Alert } from "antd";

class QRTool extends Component {
  render() {
    if (this.props.auth.plan.plan_features.CUSTOM_QRCODES === 1) {
      return (
        <iframe
          className="qr-code-gen-iframe"
          title="QR Code Generator"
          src="https://tools.letx.co/qr-code-generator/"
        />
      );
    }
    return (
      <Alert
        message="Error"
        description="Your plan does not allow you to access this feature!"
        type="error"
        showIcon
        style={{ marginTop: 30 }}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth.auth,
});

export default connect(mapStateToProps)(QRTool);
