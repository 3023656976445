import React, { Component } from "react";
import MainHeadingBar from "../Partials/MainHeadingBar";
import {
  Row,
  Skeleton,
  Alert,
  Button,
  Dropdown,
  Icon,
  Menu,
  Card,
  Table,
} from "antd";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import "./index.scss";
import Axios from "axios";
import CreateCouponModal from "./CreateCouponModal";

class Coupons extends Component {
  state = {
    loading: false,
    error: false,
    errorMsg: "",
    coupons: [],
    paging: {
      page: 1,
      total: 0,
      pageSize: 10,
      order: -1,
    },
    couponModal: {
      visible: false,
      data: {},
    },
  };

  toggleCouponModal(data) {
    this.setState({
      couponModal: {
        visible: !this.state.couponModal.visible,
        data,
      },
    });
  }

  fetchCoupons() {
    this.setState(
      {
        loading: true,
      },
      () => {
        Axios({
          url: "/zerotouch/get_coupons",
          method: "post",
          data: {
            page: this.state.paging.page,
            pageSize: this.state.paging.pageSize,
            order: this.state.paging.order,
            domid: this.props.match.params.domid,
          },
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                loading: false,
                coupons: res.data.data,
                paging: { ...this.state.paging, total: res.data.total },
              });
            } else {
              this.setState({
                loading: false,
                error: true,
                errorMsg: res.data.msg,
              });
            }
          })
          .catch((err) => {
            this.setState({
              loading: false,
              error: true,
              errorMsg: err.message,
            });
          });
      }
    );
  }

  componentDidMount = () => {
    this.fetchCoupons();
  };

  goToPage(pagination) {
    this.setState(
      {
        paging: {
          ...this.state.paging,
          page: pagination.current,
        },
      },
      () => this.fetchCoupons()
    );
  }

  setSortOrder(order) {
    this.setState(
      {
        paging: {
          ...this.state.paging,
          order: order === "asc" ? 1 : -1,
        },
      },
      () => this.fetchCoupons()
    );
  }

  render() {
    const { loading, error, errorMsg } = this.state;
    if (this.props.auth.domain.role === "store_user") {
      return <Redirect to={`/${this.props.auth.domain.id}/orders`} />;
    }
    const sortMenu = (
      <Menu>
        <Menu.Item key={0}>
          <a href="#/" onClick={() => this.setSortOrder("asc")}>
            Asc. Order
          </a>
        </Menu.Item>
        <Menu.Item key={1}>
          <a href="#/" onClick={() => this.setSortOrder("desc")}>
            Desc. Order
          </a>
        </Menu.Item>
      </Menu>
    );

    const PageWrapper = (children) => (
      <div className="categories-container">
        <MainHeadingBar
          title="Coupons"
          pageCTA={
            <span className="sort">
              Sort By Date:{" "}
              <Dropdown overlay={sortMenu}>
                <Button size="default">
                  {this.state.paging.order === 1 ? "Asc." : "Desc."} Order{" "}
                  <Icon type="down" />
                </Button>
              </Dropdown>
            </span>
          }
          cta={
            <>
              <div className="actions">
                <Button size="large" onClick={() => this.toggleCouponModal()}>
                  Create New Coupon
                </Button>
              </div>
            </>
          }
          {...this.props}
        />
        {children}
        <CreateCouponModal
          {...this.props}
          reloadList={() => this.fetchCoupons()}
          toggleCouponModal={() => this.toggleCouponModal()}
          visible={this.state.couponModal.visible}
          data={this.state.couponModal.data}
        />
      </div>
    );

    if (loading) {
      return PageWrapper(
        <Card style={{ marginBottom: "30px" }}>
          <Skeleton active />
        </Card>
      );
    }

    if (!loading && error) {
      return PageWrapper(
        <div>
          <Row>
            <Alert
              message="There was an error"
              description={errorMsg}
              type="error"
            />
          </Row>
        </div>
      );
    }
    if (!loading && !error && !this.state.coupons.length > 0) {
      return PageWrapper(
        <Card>
          <div className="campaign-empty-state" style={{ textAlign: "center" }}>
            <h3>Here for the first time?</h3>
            <p>Looks like you have not created any coupons yet. </p>
            <p>No worries! We've got you covered! </p>
            {this.props.auth.domain.role !== "analyst" && (
              <Button
                className="red-button"
                onClick={() => this.toggleCouponModal()}
              >
                Create New Coupon
              </Button>
            )}
          </div>
        </Card>
      );
    }
    const columns = [
      {
        title: "Coupon Name",
        dataIndex: "name",
      },
      {
        title: "Code",
        dataIndex: "code",
      },
      {
        title: "Actions",
        dataIndex: null,
        render: (_, row) =>
          this.props.auth.domain.role !== "analyst" ? (
            <div className="category-actions">
              <Button
                type="primary"
                onClick={() => this.toggleCouponModal(row)}
              >
                Edit Coupon
              </Button>
            </div>
          ) : (
            "No Actions"
          ),
      },
    ];

    const pagination = {
      current: this.state.paging.page,
      pageSize: this.state.paging.pageSize,
      total: this.state.paging.total,
    };

    return PageWrapper(
      <div className="category-list">
        <Row gutter={30}>
          <Table
            columns={columns}
            rowKey={(_, i) => i}
            dataSource={this.state.coupons}
            pagination={{
              ...pagination,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
            loading={this.state.loading}
            onChange={(pagination) => this.goToPage(pagination)}
          />
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth.auth,
});

export default connect(mapStateToProps)(Coupons);
