import React, { Component } from "react";
import {
  Row,
  Skeleton,
  Alert,
  Button,
  Icon,
  Table,
  Tooltip,
  Divider,
} from "antd";
import { connect } from "react-redux";
import AddTranslationModal from "./AddTranslationModal";
import "./index.scss";
import axios from "axios";

class PaymentSettings extends Component {
  state = {
    loading: false,
    error: false,
    errorMsg: "",
    translations: [],
    paging: {
      page: 1,
      total: 0,
      pageSize: 10,
      order: -1,
    },
    translationModal: {
      visible: false,
      data: null,
    },
  };

  fetch() {
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url: "/zerotouch/get_translations",
          method: "post",
          data: {
            page: this.state.paging.page,
            pageSize: this.state.paging.pageSize,
            order: this.state.paging.order,
            domid: this.props.match.params.domid,
          },
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                loading: false,
                translations: res.data.data,
              });
            } else {
              this.setState({
                loading: false,
                error: true,
                errorMsg: res.data.msg,
              });
            }
          })
          .catch((err) => {
            this.setState({
              loading: false,
              error: true,
              errorMsg: err.message,
            });
          });
      }
    );
  }

  componentDidMount = () => {
    this.fetch();
  };

  goToPage(pagination) {
    this.setState(
      {
        paging: {
          ...this.state.paging,
          page: pagination.current,
        },
      },
      () => this.fetch()
    );
  }

  toggleTranslationModal(data = null) {
    this.setState({
      translationModal: {
        visible: !this.state.translationModal.visible,
        data,
      },
    });
  }

  render() {
    const { loading, error, errorMsg } = this.state;

    if (loading) {
      return <Skeleton active />;
    }

    if (!loading && error) {
      return (
        <div>
          <Row>
            <Alert
              message="There was an error"
              description={errorMsg}
              type="error"
            />
          </Row>
        </div>
      );
    }

    const columns = [
      {
        title: "Translation Language",
        dataIndex: "translation_name",
      },
      {
        title: "Actions",
        dataIndex: null,
        render: (_, row) => (
          <div>
            <Button
              style={{
                background: "#37c627",
                borderColor: "#37c627",
                color: "white",
                marginRight: 15,
              }}
              onClick={() =>
                this.toggleTranslationModal({
                  translation_name: row.translation_name,
                  translation_data: row.translation_data,
                  edit: row._id["$id"],
                })
              }
            >
              Edit
            </Button>
          </div>
        ),
      },
    ];

    const pagination = {
      current: this.state.paging.page,
      pageSize: this.state.paging.pageSize,
      total: this.state.paging.total,
    };

    return (
      <div className="user-settings">
        <h3 className="heading">
          <span className="text">
            Translations{" "}
            <Tooltip
              title={`Here you can add and manage translation settings.`}
            >
              <Icon type="info-circle" style={{ fontSize: "1.5rem" }} />
            </Tooltip>
          </span>

          <Button
            className="add-user-btn"
            onClick={() => this.toggleTranslationModal()}
          >
            <Icon type="plus" size="large" />
            Add New Translation
          </Button>
        </h3>

        <Divider />
        <Table
          columns={columns}
          rowKey={(_, i) => i}
          dataSource={this.state.translations}
          pagination={{
            ...pagination,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
          loading={this.state.loading}
          onChange={(pagination) => this.goToPage(pagination)}
        />
        <AddTranslationModal
          {...this.props}
          {...this.state.translationModal}
          reloadList={() => this.fetch()}
          toggleTranslationModal={() => this.toggleTranslationModal()}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth.auth,
});

export default connect(mapStateToProps)(PaymentSettings);
