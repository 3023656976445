import React, { Component } from "react";
import "./index.scss";
import { Tabs } from "antd";
import AccountSettings from "./AccountSettings";
import WhitelabelDomains from "./WhitelabelDomains";
import PaymentSettings from "./PaymentSettings";
import WebhookSettings from "./WebhookSettings";
import Integrations from "./Integrations";
import MainHeadingBar from "../Partials/MainHeadingBar";
import InvoiceSettings from "./InvoiceSettings";
import OrderNotificationSettings from "./OrderNotificationSettings";
import TranslationSettings from "./TranslationSettings";
import { connect } from "react-redux";
import CustomQRCode from "./CustomQRCode";

const { TabPane } = Tabs;

class Settings extends Component {
  state = {
    activeTab:
      this.props.activeTab &&
      ["accounts", "integrations", "payments", "domains"].indexOf(
        this.props.activeTab
      ) !== -1
        ? this.props.activeTab
        : "accounts",
  };

  setActiveTab() {
    this.setState({
      activeTab: this.props.match.params.tab,
    });
  }

  componentDidMount() {
    this.setActiveTab();
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.tab !== prevProps.match.params.tab) {
      this.setActiveTab();
    }
  }

  render() {
    return (
      <div className="settings">
        <MainHeadingBar title="Settings" {...this.props} cta={<span />} />
        <Tabs
          tabPosition="left"
          activeKey={this.state.activeTab}
          onChange={(ac) => this.setState({ activeTab: ac })}
        >
          <TabPane tab="Basic Settings" key="accounts">
            {this.state.activeTab === "accounts" && (
              <AccountSettings {...this.props} />
            )}
          </TabPane>
          <TabPane tab="Integrations" key="integrations">
            {this.state.activeTab === "integrations" && (
              <Integrations {...this.props} />
            )}
          </TabPane>
          <TabPane tab="Payment Settings" key="payments">
            <PaymentSettings {...this.props} />
          </TabPane>
          <TabPane tab="Custom Domain" key="domains">
            <WhitelabelDomains {...this.props} />
          </TabPane>
          <TabPane tab="Invoice Settings" key="invoice">
            <InvoiceSettings {...this.props} />
          </TabPane>
          <TabPane tab="Order Notifications" key="order_noti">
            <OrderNotificationSettings {...this.props} />
          </TabPane>
          <TabPane tab="Translation Settings" key="translations">
            <TranslationSettings {...this.props} />
          </TabPane>
          <TabPane tab="Custom QR Code" key="qrcode">
            <CustomQRCode {...this.props} />
          </TabPane>
          <TabPane tab="Webhook Settings" key="webhooks">
            <WebhookSettings {...this.props} />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};

export default connect(mapStateToProps)(Settings);
