import React, { Component } from "react";
import "./index.scss";
import { Tabs, Alert } from "antd";
import { connect } from "react-redux";
import MainHeadingBar from "../Partials/MainHeadingBar";
import AgencySiteBuilder from "./AgencySiteBuilder";
import { Redirect } from "react-router-dom";

const { TabPane } = Tabs;

class Settings extends Component {
  render() {
    const { plan, domain } = this.props.auth;
    if (domain.role !== "owner") {
      return <Redirect to="/" />;
    }
    if (plan.plan_features.AGENCY !== 1) {
      return <Redirect to="/" />;
    }
    return (
      <div className="settings">
        <MainHeadingBar title="Agency" {...this.props} cta={<span />} />
        {this.props.auth.plan.plan_features.AGENCY === 1 ? (
          <Tabs tabPosition="left">
            <TabPane tab="Proposal" key="proposals">
              <b
                style={{ fontSize: 25, display: "flex", alignItems: "center" }}
              >
                ABC MARKETING SOLUTIONS ZeroTouch
              </b>
              <br />
              <a
                href="/downloads/agency/ABC_MARKETING_SOLUTIONS_ZeroTouch.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="ant-btn ant-btn-primary"
                style={{ marginLeft: 15 }}
              >
                DOWNLOAD PDF
              </a>
              <a
                href="/downloads/agency/ABC_MARKETING_SOLUTIONS_ZeroTouch.docx"
                target="_blank"
                rel="noopener noreferrer"
                className="ant-btn ant-btn-primary"
                style={{ marginLeft: 15 }}
              >
                DOWNLOAD WORD
              </a>
            </TabPane>
            <TabPane tab="Call & Email Scripts" key="email_scripts">
              <b
                style={{ fontSize: 25, display: "flex", alignItems: "center" }}
              >
                Cold Calling Scripts and Cold Emails
              </b>
              <br />
              <a
                href="/downloads/agency/cold_calling_scripts_and_cold_emails.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="ant-btn ant-btn-primary"
                style={{ marginLeft: 15 }}
              >
                DOWNLOAD PDF
              </a>
            </TabPane>
            <TabPane tab="Ad Copies" key="ad_copies">
              <b
                style={{ fontSize: 25, display: "flex", alignItems: "center" }}
              >
                Ad Copies
              </b>
              <br />
              <a
                href="/downloads/agency/Ad_Copies.docx"
                target="_blank"
                rel="noopener noreferrer"
                className="ant-btn ant-btn-primary"
                style={{ marginLeft: 15 }}
              >
                DOWNLOAD WORD
              </a>
            </TabPane>
            <TabPane tab="Ad Graphics" key="ads">
              <b
                style={{ fontSize: 25, display: "flex", alignItems: "center" }}
              >
                Ad Graphics
              </b>
              <br />
              <a
                href="/downloads/agency/Ad_Graphics.zip"
                target="_blank"
                rel="noopener noreferrer"
                className="ant-btn ant-btn-primary"
                style={{ marginLeft: 15 }}
              >
                DOWNLOAD
              </a>
            </TabPane>
            <TabPane tab="Agency Video" key="agency_video">
              <b
                style={{ fontSize: 25, display: "flex", alignItems: "center" }}
              >
                Agency Video
              </b>
              <br />
              <a
                href="/downloads/agency/agency_video.mp4"
                target="_blank"
                rel="noopener noreferrer"
                className="ant-btn ant-btn-primary"
                style={{ marginLeft: 15 }}
              >
                DOWNLOAD
              </a>
            </TabPane>
            <TabPane tab="Agency Site" key="agency_site">
              <AgencySiteBuilder {...this.props} />
            </TabPane>
          </Tabs>
        ) : (
          <Alert
            message="Error"
            description="Your plan does not allow you to access this section!"
            type="error"
            showIcon
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};

export default connect(mapStateToProps)(Settings);
