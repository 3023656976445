import React, { Component } from "react";
import "./index.scss";
import {
  Button,
  Divider,
  Row,
  Col,
  Input,
  Icon,
  Skeleton,
  Alert,
  message,
  Tooltip,
  Switch,
} from "antd";
import { connect } from "react-redux";
import axios from "axios";

class OrderNotificationSettings extends Component {
  state = {
    error: false,
    errorMsg: "",
    loading: false,
    saving: false,
    domain: {
      dom_emailnotifications: {
        enabled: 0,
        cc_email: "",
        smtp_host: "",
        smtp_port: "",
        smtp_username: "",
        smtp_password: "",
        smtp_fromname: "",
        smtp_fromemail: "",
        smtp_replytoemail: "",
      },
    },
    errors: {},
  };

  onChange = (k, v) => {
    this.setState((state) => ({
      domain: {
        ...state.domain,
        dom_emailnotifications: {
          ...state.domain.dom_emailnotifications,
          [k]: v,
        },
      },
    }));
  };

  onChangeSMTP = (k, v) => {
    this.onChange(k, v);
  };

  fetchSettings() {
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url: "/zerotouch/get_store_settings",
          method: "post",
          data: {
            domid: this.props.match.params.domid,
          },
        })
          .then((res) => {
            this.setState({ loading: false });
            return res;
          })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                domain: {
                  ...this.state.domain,
                  ...res.data.data,
                },
              });
            } else {
              this.setState({
                error: true,
                errorMsg: res.data.msg,
              });
            }
          })
          .catch((err) => {
            this.setState({
              error: true,
              errorMsg: "There was an error!",
            });
            console.log(err);
          });
      }
    );
  }

  validate() {
    const settings = this.state.domain.dom_emailnotifications;
    const errors = {};
    if (settings.enabled === 1) {
      if (!settings.cc_email) {
        errors.cc_email = "This is a required field!";
      }
      if (!settings.smtp_host) {
        errors.smtp_host = "This is a required field!";
      }
      if (!settings.smtp_port) {
        errors.smtp_port = "This is a required field!";
      }
      if (!settings.smtp_username) {
        errors.smtp_username = "This is a required field!";
      }
      if (!settings.smtp_password) {
        errors.smtp_password = "This is a required field!";
      }
      if (!settings.smtp_fromname) {
        errors.smtp_fromname = "This is a required field!";
      }
      if (!settings.smtp_fromemail) {
        errors.smtp_fromemail = "This is a required field!";
      }
      if (!settings.smtp_replytoemail) {
        errors.smtp_replytoemail = "This is a required field!";
      }
    }

    this.setState({
      errors,
    });

    if (Object.keys(errors).length) {
      return false;
    }
    return true;
  }

  saveSettings() {
    if (this.validate()) {
      this.setState(
        {
          saving: true,
        },
        () => {
          axios({
            url: "/zerotouch/save_email_notification_settings",
            method: "post",
            data: {
              domid: this.props.match.params.domid,
              data: this.state.domain.dom_emailnotifications,
            },
          })
            .then((res) => {
              this.setState({ saving: false });
              return res;
            })
            .then((res) => {
              if (res.data.success === 1) {
                message.success("Settings Saved Successfully!");
              } else {
                message.error(res.data.msg);
              }
            })
            .catch((err) => {
              message.error("There was an error!");
              console.log(err);
            });
        }
      );
    }
  }

  componentDidMount = () => {
    this.fetchSettings();
  };

  render() {
    const { error, errorMsg, loading } = this.state;
    if (loading) {
      return (
        <div style={{ marginBottom: "30px" }}>
          <Skeleton active />
        </div>
      );
    }

    if (!loading && error) {
      return (
        <div>
          <Row>
            <Alert
              message="There was an error"
              description={errorMsg}
              type="error"
            />
          </Row>
        </div>
      );
    }
    const domain = this.state.domain.dom_emailnotifications;
    return (
      <div className="account-settings">
        <h3 className="heading">
          <span className="text">
            Order Notifications{" "}
            <Tooltip title="Here you can manage the order email notification settings of your account.">
              <Icon type="info-circle" style={{ fontSize: "1.5rem" }} />
            </Tooltip>
          </span>
          <Button
            type="default"
            onClick={() => this.saveSettings()}
            loading={this.state.saving}
          >
            Save Settings
          </Button>
        </h3>

        <Divider />

        <Row gutter={24}>
          <Col xs={24}>
            <div className="input-container">
              <label>
                <Switch
                  checked={domain.enabled === 1}
                  style={{ color: "green" }}
                  onChange={(checked) =>
                    this.onChangeSMTP("enabled", Number(checked))
                  }
                />{" "}
                Email Order Notifications are{" "}
                {domain.enabled === 1 ? "Enabled" : "Disabled"}
              </label>
            </div>
            {domain.enabled === 1 ? (
              <>
                <div className="input-container">
                  <p className="label">CC Email:</p>
                  <Input
                    value={domain.cc_email}
                    onChange={(e) => this.onChange("cc_email", e.target.value)}
                  />
                  {this.state.errors.cc_email && (
                    <p className="error-label">{this.state.errors.cc_email}</p>
                  )}
                </div>
                <div className="input-container">
                  <p className="label">SMTP Host:</p>
                  <Input
                    value={domain.smtp_host}
                    onChange={(e) =>
                      this.onChangeSMTP("smtp_host", e.target.value)
                    }
                  />
                  {this.state.errors.smtp_host && (
                    <p className="error-label">{this.state.errors.smtp_host}</p>
                  )}
                </div>
                <div className="input-container">
                  <p className="label">SMTP Port:</p>
                  <Input
                    value={domain.smtp_port}
                    onChange={(e) =>
                      this.onChangeSMTP(
                        "smtp_port",
                        e.target.value.replace(/[\D]/g, "")
                      )
                    }
                  />
                  {this.state.errors.smtp_port && (
                    <p className="error-label">{this.state.errors.smtp_port}</p>
                  )}
                </div>
                <div className="input-container">
                  <p className="label">SMTP Username:</p>
                  <Input
                    value={domain.smtp_username}
                    onChange={(e) =>
                      this.onChangeSMTP("smtp_username", e.target.value)
                    }
                  />
                  {this.state.errors.smtp_username && (
                    <p className="error-label">
                      {this.state.errors.smtp_username}
                    </p>
                  )}
                </div>
                <div className="input-container">
                  <p className="label">SMTP Password:</p>
                  <Input.Password
                    value={domain.smtp_password}
                    onChange={(e) =>
                      this.onChangeSMTP("smtp_password", e.target.value)
                    }
                  />
                  {this.state.errors.smtp_password && (
                    <p className="error-label">
                      {this.state.errors.smtp_password}
                    </p>
                  )}
                </div>
                <div className="input-container">
                  <p className="label">SMTP From Name:</p>
                  <Input
                    value={domain.smtp_fromname}
                    onChange={(e) =>
                      this.onChangeSMTP("smtp_fromname", e.target.value)
                    }
                  />
                  {this.state.errors.smtp_fromname && (
                    <p className="error-label">
                      {this.state.errors.smtp_fromname}
                    </p>
                  )}
                </div>
                <div className="input-container">
                  <p className="label">SMTP From Email:</p>
                  <Input
                    value={domain.smtp_fromemail}
                    onChange={(e) =>
                      this.onChangeSMTP("smtp_fromemail", e.target.value)
                    }
                  />
                  {this.state.errors.smtp_fromemail && (
                    <p className="error-label">
                      {this.state.errors.smtp_fromemail}
                    </p>
                  )}
                </div>
                <div className="input-container">
                  <p className="label">SMTP Reply To Email:</p>
                  <Input
                    value={domain.smtp_replytoemail}
                    onChange={(e) =>
                      this.onChangeSMTP("smtp_replytoemail", e.target.value)
                    }
                  />
                  {this.state.errors.smtp_replytoemail && (
                    <p className="error-label">
                      {this.state.errors.smtp_replytoemail}
                    </p>
                  )}
                </div>
              </>
            ) : null}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};

export default connect(mapStateToProps)(OrderNotificationSettings);
