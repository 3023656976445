import React, { Component } from "react";
import { Modal, Input, message, Button, Select, Icon } from "antd";
import "./index.scss";
import LibraryModal from "../../Library/LibraryModal";
import { CDN_URL } from "../../../../config";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import axios from "axios";
const { Option } = Select;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  padding: 5,
  border: "1px solid #bbb",
  borderRadius: 4,
  margin: "5px 0",
  background: "white",
  userSelect: "none",

  // drag changes
  boxShadow: isDragging ? "1px 3px 3px rgba(0,0,0,0.3)" : "none",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "#a5d7ff" : "white",
  padding: 8,
  width: "100%",
});
class CreateCategoryModal extends Component {
  state = {
    loading: false,
    category: {
      name: "",
      products: [],
      icon: "",
    },
    products: [],
    libraryModal: {
      visible: false,
      tab: "upload",
    },
    errors: {},
  };

  componentDidUpdate(pp) {
    if (pp.visible !== this.props.visible) {
      this.setState({
        category: {
          name: "",
          products: [],
          icon: "",
        },
      });
    }
    if (
      this.props.visible &&
      this.props.data &&
      JSON.stringify(this.props.data) !== JSON.stringify(pp.data)
    ) {
      this.setState({
        category: this.props.data,
      });
    }
  }

  fetchProducts() {
    axios({
      url: "/zerotouch/get_products",
      method: "post",
      data: {
        page: 1,
        pageSize: 100000,
        domid: this.props.match.params.domid,
      },
    })
      .then((res) => {
        if (res.data.success === 1) {
          this.setState({
            products: res.data.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  toggleLibraryModal(tab = "upload") {
    this.setState((state) => ({
      libraryModal: {
        ...state.libraryModal,
        visible: !state.libraryModal.visible,
        tab,
      },
    }));
  }

  onChange = (k, v) => {
    this.setState((state) => ({
      category: {
        ...state.category,
        [k]: v,
      },
    }));
  };

  onChoose(data) {
    this.onChange("icon", data.media_filename);
    this.toggleLibraryModal();
  }

  validate = () => {
    const category = this.state.category;
    const errors = {};
    if (!category.name) {
      errors.name = "This is a required field!";
    }

    this.setState({ errors });
    if (Object.keys(errors).length > 0) {
      return false;
    }
    return true;
  };

  onSubmit = () => {
    if (this.validate()) {
      const category = {
        name: this.state.category.name,
        products: this.state.category.products,
        icon: this.state.category.icon,
      };
      if (this.state.category._id) {
        category.edit = this.state.category._id["$id"];
      }
      this.setState(
        {
          loading: true,
        },
        () => {
          axios({
            url: "/zerotouch/add_category",
            method: "post",
            data: {
              category,
              domid: this.props.match.params.domid,
            },
          })
            .then((res) => {
              this.setState({
                loading: false,
              });
              return res;
            })
            .then((res) => {
              if (res.data.success === 1) {
                this.props.toggleCategoryModal();
                this.props.reloadList();
              } else {
                message.error(res.data.msg);
              }
            })
            .catch((err) => {
              message.error("There was an error!");
            });
        }
      );
    }
  };

  componentDidMount() {
    this.fetchProducts();
  }

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      return result;
    };

    const items = reorder(
      this.state.category.products,
      result.source.index,
      result.destination.index
    );

    console.log(items);

    this.onChange("products", items);
  };

  removeProduct(id) {
    this.onChange(
      "products",
      this.state.category.products.filter((p) => p !== id)
    );
  }

  render() {
    const errors = this.state.errors;
    return (
      <Modal
        title="Create Category"
        visible={this.props.visible}
        className="categoryModal"
        onCancel={this.props.toggleCategoryModal}
        closable={false}
        maskClosable={false}
        footer={[
          <Button
            key="save"
            onClick={() => this.onSubmit()}
            style={{ border: "none", background: "#37C627", color: "white" }}
          >
            Save Category
          </Button>,
          <Button
            key="cancel"
            onClick={() => this.props.toggleCategoryModal()}
            style={{ border: "1px solid #707070", color: "#707070" }}
          >
            Cancel
          </Button>,
        ]}
      >
        <div>
          <div className={`form-item-group ${errors.name ? "has-error" : ""}`}>
            <label className="ant-form-item-label">Category Name:</label>
            <Input
              placeholder="Enter category name"
              value={this.state.category.name}
              onChange={(e) => this.onChange("name", e.target.value)}
            />
            {errors.name && <p className="error-label">{errors.name}</p>}
          </div>
          <div className="form-item-group">
            <label className="ant-form-item-label">Choose An Icon*</label>
            <div className="upload-actions">
              {this.state.category.icon && (
                <img
                  alt="category logo"
                  src={`${CDN_URL}images/${this.state.category.icon}`}
                  style={{
                    maxWidth: "7.14rem",
                    marginRight: 10,
                    border: "1px solid #ccc",
                  }}
                />
              )}
              <div
                className="upload-action"
                onClick={() => this.toggleLibraryModal("upload")}
              >
                <p>
                  <Icon type="upload" style={{ color: "#D9D9D9" }} /> <br />
                  Upload
                </p>
              </div>
              <div
                className="upload-action"
                onClick={() => this.toggleLibraryModal("library")}
              >
                <p>
                  <Icon type="folder-open" style={{ color: "#D9D9D9" }} />{" "}
                  <br />
                  Library
                </p>
              </div>
            </div>
          </div>

          <div className={`form-item-group`}>
            <label className="ant-form-item-label">
              Select & Re-arrange Products:
            </label>
            <Select
              placeholder="Select products to add"
              value={this.state.category.products}
              style={{ width: "100%" }}
              mode="multiple"
              className="products-select"
              onChange={(val) => this.onChange("products", val)}
            >
              {this.state.products.map((c) => (
                <Option key={c._id["$id"]} value={c._id["$id"]}>
                  {c.name}
                </Option>
              ))}
            </Select>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {this.state.category.products.map((cp, index) => {
                      const product = this.state.products.find(
                        (p) => cp === p._id["$id"]
                      );
                      if (product) {
                        return (
                          <Draggable key={cp} draggableId={cp} index={index}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <div style={{ display: "flex" }}>
                                  <img
                                    style={{
                                      width: 50,
                                      height: 50,
                                      objectFit: "cover",
                                      border: "1px solid #ccc",
                                    }}
                                    src={`${CDN_URL}images/${
                                      product.featured_image
                                    }`}
                                    alt="product"
                                  />
                                  <div style={{ flex: 1, paddingLeft: 10 }}>
                                    <p
                                      style={{ fontWeight: "bold", margin: 0 }}
                                    >
                                      {product.name}
                                    </p>
                                    <p
                                      style={{ fontWeight: "bold", margin: 0 }}
                                    >
                                      {product.currency} {product.cost}
                                    </p>
                                  </div>
                                  <div>
                                    <a
                                      href="#!"
                                      onClick={() => this.removeProduct(cp)}
                                    >
                                      <Icon
                                        type="plus"
                                        style={{ transform: "rotate(45deg)" }}
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        );
                      }
                      return null;
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
        <LibraryModal
          {...this.props}
          tab={this.state.libraryModal.tab}
          onCancel={() => this.toggleLibraryModal()}
          visible={this.state.libraryModal.visible}
          onChoose={(data) => this.onChoose(data)}
        />
      </Modal>
    );
  }
}

export default CreateCategoryModal;
