import React, { Component } from "react";
import { Modal, Input, message, Button, Select } from "antd";
import "./index.scss";
import axios from "axios";
const { Option } = Select;

class AddGatewayModal extends Component {
  state = {
    loading: false,
    gateway: {
      type: "paypal",
      client_id: "",
      client_secret: "",
    },
    errors: {},
  };

  onChange = (k, v) => {
    this.setState((state) => ({
      gateway: {
        ...state.gateway,
        [k]: v,
      },
    }));
  };

  componentDidUpdate(pp) {
    if (pp.visible !== this.props.visible) {
      this.setState({
        loading: false,
        gateway: {
          type: "stripe",
          client_id: "",
          client_secret: "",
        },
        errors: {},
      });
    }
    if (
      this.props.visible &&
      this.props.data &&
      JSON.stringify(this.props.data) !== JSON.stringify(pp.data)
    ) {
      this.setState({
        gateway: {
          ...this.state.gateway,
          ...this.props.data,
        },
      });
    }
  }

  validate = () => {
    const gateway = this.state.gateway;
    const errors = {};
    if (!gateway.client_id) {
      errors.client_id = "This is a required field!";
    }
    if (!gateway.client_secret) {
      errors.client_secret = "This is a required field!";
    }

    this.setState({ errors });
    if (Object.keys(errors).length > 0) {
      return false;
    }
    return true;
  };

  onSubmit = () => {
    if (this.validate()) {
      this.setState(
        {
          loading: true,
        },
        () => {
          axios({
            url: "/zerotouch/add_payment_method",
            method: "post",
            data: {
              ...this.state.gateway,
              domid: this.props.match.params.domid,
            },
          })
            .then((res) => {
              this.setState({
                loading: false,
              });
              return res;
            })
            .then((res) => {
              if (res.data.success === 1) {
                message.success(
                  `${
                    this.state.gateway.edit ? "Updated" : "Added"
                  } Successfully!`
                );
                this.props.toggleGatewayModal();
                this.props.reloadList();
              } else {
                message.error(res.data.msg);
              }
            })
            .catch((err) => {
              message.error("There was an error!");
            });
        }
      );
    }
  };

  render() {
    const errors = this.state.errors;
    return (
      <Modal
        title={`${this.state.gateway.edit ? "Edit" : "Add"} Payment Gateway`}
        visible={this.props.visible}
        className="categoryModal"
        onCancel={this.props.toggleStationModal}
        closable={false}
        footer={[
          <Button
            onClick={() => this.onSubmit()}
            style={{ border: "none", background: "#37C627", color: "white" }}
          >
            {this.state.gateway.edit ? "Update" : "Add"} Gateway
          </Button>,
          <Button
            onClick={() => this.props.toggleGatewayModal()}
            style={{ border: "1px solid #707070", color: "#707070" }}
          >
            Cancel
          </Button>,
        ]}
      >
        <div>
          <div className={`form-item-group`}>
            <label className="ant-form-item-label">Select Gateway:</label>
            <Select
              placeholder="Select Gateway"
              value={this.state.gateway.type}
              onChange={(val) => this.onChange("type", val)}
              style={{ width: "100%" }}
              disabled={this.state.gateway.edit}
            >
              <Option value="stripe">Stripe</Option>
              <Option value="razorpay">Razorpay</Option>
              <Option value="paypal">Paypal</Option>
            </Select>
          </div>

          <div
            className={`form-item-group ${errors.client_id ? "has-error" : ""}`}
          >
            <label className="ant-form-item-label">Client ID:</label>
            <Input
              placeholder="Enter Client ID"
              value={this.state.gateway.client_id}
              onChange={(e) => this.onChange("client_id", e.target.value)}
            />
            {errors.client_id && (
              <p className="error-label">{errors.client_id}</p>
            )}
          </div>
          <div
            className={`form-item-group ${
              errors.client_secret ? "has-error" : ""
            }`}
          >
            <label className="ant-form-item-label">Client Secret:</label>
            <Input
              placeholder="Enter Client Secret"
              value={this.state.gateway.client_secret}
              onChange={(e) => this.onChange("client_secret", e.target.value)}
            />
            {errors.client_secret && (
              <p className="error-label">{errors.client_secret}</p>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

export default AddGatewayModal;
