import React, { Component } from "react";
import MainHeadingBar from "../Partials/MainHeadingBar";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import "./index.scss";

class BonusPage extends Component {
  render() {
    const { user, domain } = this.props.auth;
    if (domain.role !== "owner") {
      return <Redirect to="/" />;
    }
    if (
      user.origin &&
      (user.origin !== "JVZOO" && user.origin !== "THRIVECART")
    ) {
      return <Redirect to="/" />;
    }

    return (
      <div className="bonus-page">
        <MainHeadingBar
          title="Bonuses & Special Access"
          {...this.props}
          hideCTA
        />
        <div className="col-md-12">
          <h3>Your Special Gifts</h3>
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Product Name</th>
                <th>Description</th>
                <th>Access URL</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ with: "200px" }}>Campaign Improviser</td>
                <td>
                  Modify marketing campaigns to build trust and increase
                  footfalls
                </td>
                <td style={{ width: "150px" }}>
                  <a
                    href="https://docs.google.com/document/d/1beTyoBlD9UkkPj52ha7HnFXeICurGIEwXX_loe2jLIs/edit?usp=sharing"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Access Here
                  </a>
                </td>
              </tr>
              <tr>
                <td style={{ with: "200px" }}>PRO Retainer Secrets</td>
                <td>Secrets to retaining more agency clients</td>
                <td style={{ width: "150px" }}>
                  <a
                    href="https://docs.google.com/document/d/1IZM85yjV2JqkOWFKMqJ42M-_KjmzOwxqd7N5GAG6KhM/edit?usp=sharing"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Access Here
                  </a>
                </td>
              </tr>
              <tr>
                <td style={{ with: "200px" }}>Coupon King</td>
                <td>
                  coupon marketing guide to promote digital payments and
                  increase pre-orders
                </td>
                <td style={{ width: "150px" }}>
                  <a
                    href="https://docs.google.com/document/d/1izdt8QVBkb8pczOwkHjN1RMyXwaG4O67lKD0sO8hR58/edit?usp=sharing"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Access Here
                  </a>
                </td>
              </tr>
              <tr>
                <td style={{ with: "200px" }}>Testimonial Scraper</td>
                <td>
                  Best practices tocollect testimonials and build social proof
                  for marketing agencies
                </td>
                <td style={{ width: "150px" }}>
                  <a
                    href="https://docs.google.com/document/d/1lecu86g-9zicO_GrfZnvpNLEthR2vyiNAvzP1Hqdom4/edit?usp=sharing"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Access Here
                  </a>
                </td>
              </tr>
              <tr>
                <td style={{ with: "200px" }}>Marketing Guerilla</td>
                <td>
                  mini hacks for local businesses for helping Local Clients
                  flourish
                </td>
                <td style={{ width: "150px" }}>
                  <a
                    href="https://docs.google.com/document/d/1FGnlebyImkqGUB_KrPMYbDbKUyJNH6JnxrHCS2pyk2g/edit?usp=sharing"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Access Here
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth.auth,
});

export default connect(mapStateToProps)(BonusPage);
