import React, { Component } from "react";
import { Dropdown, Menu } from "antd";
import { connect } from "react-redux";
import Script from "react-load-script";

const SDK_URL = "https://cdn.nolt.io/widgets.js";
const BOARD_URL = "https://zerotouch.nolt.io";
// const JWT = "eyJhbGciOiJ...";

class FeedbackWidget extends Component {
  constructor(props) {
    super(props);
    this.noltTrigger = React.createRef();
  }

  handleScriptCreate() {
    console.log("nolt script created");
  }

  handleScriptError() {
    console.log("nolt script error");
  }

  handleScriptLoad() {
    console.log("nolt script loaded");
    const { nolt } = window;

    nolt("init", { selector: ".nolt-trigger", url: BOARD_URL });

    if (this.props.auth.user.noltToken) {
      nolt("identify", { jwt: this.props.auth.user.noltToken });
    }
  }

  triggerNolt() {
    if (this.noltTrigger.current) {
      this.noltTrigger.current.click();
    }
  }

  render() {
    if (
      !this.props.auth ||
      !this.props.auth.domain.role ||
      this.props.auth.domain.role !== "owner"
    ) {
      return null;
    }
    return (
      <>
        <Dropdown
          overlayStyle={{ zIndex: 9999999 }}
          overlay={
            <Menu>
              <Menu.Item>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={this.props.auth.host.kb_url}
                >
                  Knowledgebase
                </a>
              </Menu.Item>
              <Menu.Item>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={this.props.auth.host.support_url}
                >
                  Support
                </a>
              </Menu.Item>
              <Menu.Item>
                <a href="#!" onClick={() => this.triggerNolt()}>
                  Feature Request
                </a>
              </Menu.Item>
              <Menu.Item>
                <a href="#!" onClick={() => this.triggerNolt()}>
                  Roadmap
                </a>
              </Menu.Item>
              <Menu.Item>
                <a href="#!" onClick={() => this.triggerNolt()}>
                  Report a bug
                </a>
              </Menu.Item>
            </Menu>
          }
          placement="bottomLeft"
        >
          <a
            href="#!"
            style={{
              position: "fixed",
              bottom: 18,
              right: 18,
              color: "#007bff",
            }}
          >
            <svg
              viewBox="0 0 20 20"
              fill="currentColor"
              style={{ width: 64, height: 64 }}
            >
              <circle cx="10" cy="10" r="8" fill="white" />
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                clipRule="evenodd"
              />
            </svg>
          </a>
        </Dropdown>
        <button
          className="nolt-trigger"
          ref={this.noltTrigger}
          style={{ display: "none" }}
        />
        <Script
          url={SDK_URL}
          onCreate={this.handleScriptCreate.bind(this)}
          onError={this.handleScriptError.bind(this)}
          onLoad={this.handleScriptLoad.bind(this)}
        />
        <Script
          url="https://app.productstash.io/js/productstash-embed.js"
          defer="defer"
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth.auth,
});
export default connect(mapStateToProps)(FeedbackWidget);
