import React, { Component } from "react";
import MainHeadingBar from "../Partials/MainHeadingBar";
import { connect } from "react-redux";
import "./index.scss";
import { Row, Card, Col } from "antd";
import Meta from "antd/lib/card/Meta";
import link1Image from "../../../assets/images/dfy_catalog/1.png";
import link2Image from "../../../assets/images/dfy_catalog/2.png";
import link3Image from "../../../assets/images/dfy_catalog/3.png";
import link4Image from "../../../assets/images/dfy_catalog/4.png";
import link5Image from "../../../assets/images/dfy_catalog/5.png";
import link6Image from "../../../assets/images/dfy_catalog/6.png";
import link7Image from "../../../assets/images/dfy_catalog/7.png";
import link8Image from "../../../assets/images/dfy_catalog/8.png";
import link9Image from "../../../assets/images/dfy_catalog/9.png";
import link10Image from "../../../assets/images/dfy_catalog/10.png";
import link11Image from "../../../assets/images/dfy_catalog/11.png";
import link12Image from "../../../assets/images/dfy_catalog/12.png";
import link13Image from "../../../assets/images/dfy_catalog/13.png";
import link14Image from "../../../assets/images/dfy_catalog/14.png";
import link15Image from "../../../assets/images/dfy_catalog/15.png";
import link16Image from "../../../assets/images/dfy_catalog/16.png";
import link17Image from "../../../assets/images/dfy_catalog/17.png";
import link18Image from "../../../assets/images/dfy_catalog/18.png";
import link19Image from "../../../assets/images/dfy_catalog/19.png";
import link20Image from "../../../assets/images/dfy_catalog/20.png";

class DFYCatalog extends Component {
  state = {
    cards: [
      {
        url: "https://store.zerotouch.agency/groceries-uk",
        image: link1Image,
      },
      {
        url: "https://store.zerotouch.agency/uk-local-stores",
        image: link2Image,
      },
      {
        url: "https://store.zerotouch.agency/French-Local-Groceries/",
        image: link3Image,
      },
      {
        url: "https://store.zerotouch.agency/German-popular-Foods",
        image: link4Image,
      },
      {
        url: "https://store.zerotouch.agency/Russian-Local-stores",
        image: link5Image,
      },
      {
        url: "https://store.zerotouch.agency/Russian-Fast-Foods-and-Beverages",
        image: link6Image,
      },
      {
        url: "https://store.zerotouch.agency/British-Foods",
        image: link7Image,
      },
      {
        url: "https://store.zerotouch.agency/food-circle",
        image: link8Image,
      },
      {
        url: "https://store.zerotouch.agency/Russian-Foods-and-Groceries",
        image: link9Image,
      },
      {
        url: "https://store.zerotouch.agency/Italian-Fashion-Wears",
        image: link10Image,
      },
      {
        url: "https://store.zerotouch.agency/Italian-Foods-and-Beverages",
        image: link11Image,
      },
      {
        url: "https://store.zerotouch.agency/German-Local-foods-and-Groceries",
        image: link12Image,
      },
      {
        url: "https://store.zerotouch.agency/French-Local-Foods-and-Stores",
        image: link13Image,
      },
      {
        url: "https://store.zerotouch.agency/Cakes",
        image: link14Image,
      },
      {
        url: "https://store.zerotouch.agency/Local-Grocery-Store",
        image: link15Image,
      },
      {
        url: "https://store.zerotouch.agency/Local-Furnitures",
        image: link16Image,
      },
      {
        url: "https://store.zerotouch.agency/Electronics",
        image: link17Image,
      },
      {
        url: "https://store.zerotouch.agency/Ice-Cream-Parlour",
        image: link18Image,
      },
      {
        url: "https://store.zerotouch.agency/Hair-Style",
        image: link19Image,
      },
      {
        url: "https://store.zerotouch.agency/Skin-Care",
        image: link20Image,
      },
    ],
  };
  render() {
    return (
      <div className="bonus-page">
        <MainHeadingBar title="DFY Catalog Templates" {...this.props} hideCTA />
        <Row gutter={20}>
          {this.state.cards.map((link, index) => {
            return (
              <Col xs={6} key={index}>
                <Card
                  style={{
                    marginBottom: 30,
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                    overflow: "hidden",
                  }}
                  cover={
                    <div
                      style={{
                        height: 500,
                        background: `url(${link.image})`,
                      }}
                    >
                      <img
                        alt={`Cue Card ${index + 1}`}
                        src={link.image}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                          backdropFilter: `blur(10px) brightness(0.8)`,
                        }}
                      />
                    </div>
                  }
                >
                  <Meta
                    style={{ textAlign: "center" }}
                    title={
                      <a
                        href={link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Preview Template
                      </a>
                    }
                  />
                </Card>
              </Col>
            );
          })}
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth.auth,
});

export default connect(mapStateToProps)(DFYCatalog);
